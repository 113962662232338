import React, { useCallback, useEffect, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FormProvider } from '@abyss/web/ui/FormProvider';
import { isEmpty, isEqual, isNil, isUndefined } from 'lodash';
import { useForm } from '@abyss/web/hooks/useForm';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { isValidStep } from '@src/routes/private/ActionPaths/screens/Manage/components/Wizard/includes/functions';
import {
  useSelectedCommonCriteria
} from '@src/routes/private/ActionPaths/screens/Manage/components/Wizard/hooks/useSelectedCommonCriteria';
import { useAssets } from './hooks/useAssets/useAssets';
import { Layout } from './components/Layout';
import { useWizardContext } from './context';
import { useSave } from './hooks/useSave';

/**
 * Extension: Wizard
 * Screens: Create, Edit
 *
 * This is step-by-step component to compose an "action path". Supports both creation and modification of an action
 * path.
 *
 * @returns {Element}
 * @constructor
 */
export const Wizard = () => {
  const router = useRouter();

  const [previouslySubmittedValues, setPreviouslySubmittedValues] = useState({});

  const { handleSelected: setSelectedCommonEntrance, selected: selectedCommonEntrance } =
    useSelectedCommonCriteria('entrance');
  const { handleSelected: setSelectedCommonExit, selected: selectedCommonExit } = useSelectedCommonCriteria('exit');

  const { actionPath, action, nextStep, currentStep, mode, isManual } = useWizardContext();
  const { currentRoute } = useRoutesContext();
  const { handleSave, isSaving } = useSave();
  const { data: assets, isLoading: isLoadingAssets } = useAssets();

  const defaultValues = {
    id: '',
    name: '',
    status: 'DRAFT',
    manualAssociation: mode === 'manual',
    exitCriteriaMethod: 'DOES_NOT_MEET_ENTRANCE_CRITERIA',
    criteria: {
      entrance: {
        additional: [{ column: '', condition: '', value: '' }],
        common: [],
        commonIds: [],
        commonCriteriaVersionsIds: [],
        merged: [],
      },
      exit: {
        additional: [{ column: '', condition: '', value: '' }],
        common: [],
        commonIds: [],
        commonCriteriaVersionsIds: [],
        merged: [],
      },
    },
    remediation: {
      assignments: [
        {
          actionId: '',
          actionName: '',
          impactedSource: '',
          retryAttempts: '',
          retryInterval: '',
          retryIntervalUnit: '',
        },
      ],
      actionPathScopeCode: 'CURRENT_FUTURE',
      remediationMethod: 'OTHER',
      notes: '',
    },
  };

  const form = useForm({ defaultValues });

  const { isDirty, isValid, isSubmitting } = form?.formState;

  const formValues = form?.getValues();

  /**
   * Updates the form values with API data only if the form is in its default state.
   */
  useEffect(() => {
    if (action === 'edit' && !isEmpty(actionPath) && defaultValues?.id === formValues?.id) {
      const data = defaultValues;

      data.id = actionPath?.id;
      data.name = actionPath?.name;
      data.status = actionPath?.status;

      data.remediation.actionPathScopeCode = actionPath?.actionPathScopeCode;
      data.remediation.remediationMethod = actionPath?.remediationMethod;
      data.remediation.notes = actionPath?.note || '';
      data.criteria = actionPath?.criteria;
      data.exitCriteriaMethod = actionPath?.exitCriteriaMethod;
      data.manualAssociation = actionPath?.manualAssociation;
      data.remediation.assignments = actionPath?.assignments;

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });
    }
  }, [action, actionPath, formValues]);

  /**
   * Extract filters from the URL set by the Risk Analysis screen and apply them to the form values.
   */
  useEffect(() => {
    if (action === 'create' && mode === 'automatic' && isEmpty(actionPath) && !isNil(currentRoute?.params?.key)) {
      const decodedKey = Buffer.from(currentRoute?.params?.key, 'base64').toString('utf-8');
      const riskAnalysisState = JSON.parse(decodedKey);

      router?.navigate('/action-paths/0/create/automatic/draft/step/1');

      const data = {
        ...defaultValues,
        criteria: riskAnalysisState?.criteria,
      };

      form?.reset(data, {
        keepDirty: false,
        keepDirtyValues: false,
        keepErrors: false,
        keepIsValid: false,
        keepSubmitCount: true,
        keepTouched: false,
        keepValues: false,
      });

      form?.validate(
        'name',
        () => {},
        () => {}
      );
    }
  }, [action, actionPath, currentRoute?.params?.key, mode]);

  /**
   * handleSubmit
   *
   * Handles form submission and stores the submitted values to prevent duplicate API calls.
   *
   * @type {(function(*): Promise<void>)|*}
   */
  const handleSubmit = useCallback(
    async (submittedValues) => {
      if (isEqual(previouslySubmittedValues, submittedValues)) {
        return;
      }

      setPreviouslySubmittedValues(submittedValues);

      if (!isSaving && isValid && !isSubmitting && isDirty) {
        const payload = { ...submittedValues };

        if (action === 'edit') {
          payload.manualAssociation = actionPath?.manualAssociation;
        }

        const response = await handleSave(payload);

        if (!isEmpty(response)) {
          const data = {
            ...response,
            ...{
              remediation: {
                assignments: response?.assignments,
                actionPathScopeCode: response?.actionPathScopeCode,
                remediationMethod: response?.remediationMethod,
                notes: response?.note || '',
              },
            },
          };

          form?.reset(data, {
            keepDirty: false,
            keepDirtyValues: false,
            keepErrors: false,
            keepIsValid: false,
            keepSubmitCount: true,
            keepTouched: false,
            keepValues: false,
          });
        }

        if (action === 'create') {
          form?.reset(defaultValues, {
            keepDirty: false,
            keepDirtyValues: false,
            keepErrors: false,
            keepIsValid: false,
            keepSubmitCount: true,
            keepTouched: false,
            keepValues: false,
          });

          router?.navigate(`/action-paths/${response?.id}/edit/${mode}/draft/step/${nextStep?.order}`);
        }
      }
    },
    [action, actionPath, isDirty, isSaving, isSubmitting, isValid, mode, nextStep]
  );

  const CurrentStep = currentStep?.component;

  const validName = !!isEmpty(form?.formState?.errors?.name);
  const isCurrentStepValid = isValidStep(
    currentStep,
    validName,
    form?.formState?.errors,
    formValues,
    isManual,
    selectedCommonEntrance,
    selectedCommonExit
  );

  useEffect(() => {
    form?.setValue('criteria.entrance.commonIds', selectedCommonEntrance?.commonIds, { shouldDirty: true });
    form?.setValue('criteria.entrance.commonCriteriaVersionsIds', selectedCommonEntrance?.commonCriteriaVersionsIds, {
      shouldDirty: true,
    });
    form?.setValue('criteria.exit.commonIds', selectedCommonExit?.commonIds, { shouldDirty: true });
    form?.setValue('criteria.exit.commonCriteriaVersionsIds', selectedCommonExit?.commonCriteriaVersionsIds, {
      shouldDirty: true,
    });
  }, [selectedCommonEntrance, selectedCommonExit]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/Manage/components/Wizard/Wizard.jsx">
      <FormProvider state={form} autoComplete="off" highlighted>
        <Layout
          defaultValues={defaultValues}
          form={form}
          handleSubmit={handleSubmit}
          isLoading={isLoadingAssets}
          isCurrentStepValid={isCurrentStepValid}
        >
          {!isUndefined(CurrentStep) && (
            <CurrentStep
              assets={assets}
              defaultValues={defaultValues}
              previouslySubmittedValues={previouslySubmittedValues}
              form={form}
              isLoading={isSaving}
              setSelectedCommonEntrance={setSelectedCommonEntrance}
              setSelectedCommonExit={setSelectedCommonExit}
            />
          )}
        </Layout>
      </FormProvider>
    </ErrorHandler>
  );
};

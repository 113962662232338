import fields from '@src/routes/private/ActionPaths/includes/fields.json';
import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { SelectInput } from '@abyss/web/ui/SelectInput';

const { remediationMethod: remediationMethodField } = fields;

/**
 * Field: RemediationMethod
 *
 * Select the type of remediation method for the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RemediationMethod = (props) => {
  const { form } = props;

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/Manage/components/Wizard/components/fields/RemediationMethod/RemediationMethod.jsx">
      <SelectInput
        {...remediationMethodField}
        onChange={() => {
          form?.validate(
            `remediation[remediationMethod]`,
            () => {},
            () => {}
          );
        }}
      />
    </ErrorHandler>
  );
};

RemediationMethod.propTypes = {
  form: PropTypes.shape({
    validate: PropTypes.func,
  }),
};

RemediationMethod.defaultProps = {
  form: {
    validate: () => {},
  },
};

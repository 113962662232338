import PropTypes from 'prop-types';
import React from 'react';
import { Alert } from '@abyss/web/ui/Alert';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty } from 'lodash';
import { Table } from './components/Table';

/**
 * Dates
 *
 * Displays dates affiliated with a risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Dates = (props) => {
  const { riskRecord } = props;

  const data = {
    streamReceived: riskRecord?.streamTimeReceived,
    lastModifiedDate: riskRecord?.lastModifiedDate,
    createdDate: riskRecord?.createdDate,
    replayStreamReceived: riskRecord?.replayStreamTimeReceived,
  };

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/Dates/Dates.jsx">
      {isEmpty(data) ? (
        <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }}>
          <Alert title="There are no dates affiliated with this risk record." variant="info" />
        </Grid.Col>
      ) : (
        <Table data={data} />
      )}
    </ErrorHandler>
  );
};

Dates.propTypes = {
  riskRecord: PropTypes.shape({
    streamTimeReceived: PropTypes.string,
    lastModifiedDate: PropTypes.string,
    createdDate: PropTypes.string,
    replayStreamTimeReceived: PropTypes.string,
  }),
};

Dates.defaultProps = {
  riskRecord: {},
};

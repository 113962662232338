import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Widget } from '@src/components/Widget';
import configuration from '../../../../../includes/configuration.json';
import { widgets } from './widgets';

/**
 * Results
 *
 * Displays a wealth of information regarding the results of an EID.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Results = (props) => {
  const { assets, currentTarget, handleSearch, riskRecord, targetRef } = props;

  /**
   * check if the EID has a DOB attribute
   */
  const hasDOB = useMemo(() => {
    return riskRecord?.ireRiskRecord?.remediationFindings?.find((attribute) => {
      return attribute.attributeType === 'dob';
    });
  }, [riskRecord?.ireRiskRecord?.remediationFindings]);

  /**
   * check if the EID has an SSN attribute
   */
  const hasSSN = useMemo(() => {
    return riskRecord?.ireRiskRecord?.remediationFindings?.find((attribute) => {
      return attribute.attributeType === 'ssn';
    });
  }, [riskRecord?.ireRiskRecord?.remediationFindings]);

  /**
   * add icon component to each widget
   * add href to each menu item
   * add back to top
   */
  const theWidgets = useMemo(() => {
    return configuration
      .map((widgetItem) => {
        const theWidgetItem = { ...widgetItem };

        theWidgetItem.icon = <IconSymbol color="#000000" icon={widgetItem?.icon} variant="outlined" />;

        return theWidgetItem;
      })
      .filter((widgetItem) => {
        if (widgetItem?.accessor.includes('dob') && !hasDOB) {
          return false;
        }

        return !(widgetItem?.accessor.includes('ssn') && !hasSSN);
      });
  }, [configuration, hasSSN, hasDOB]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/Results.jsx">
      <Layout.Stack
        alignItems="left"
        alignLayout="left"
        css={{
          width: '100%',
          '> div': {
            width: '100%',
          },
        }}
        grow
        id="eidSearchResults"
      >
        {theWidgets.map((theWidget) => {
          const Component = widgets[theWidget?.accessor];

          return (
            <Widget
              collapsed={false}
              collapsible
              description={theWidget?.description}
              icon={theWidget?.icon}
              title={theWidget?.title}
            >
              <div ref={theWidget?.accessor === currentTarget ? targetRef : null}>
                <Component assets={assets} handleSearch={handleSearch} options={theWidget} riskRecord={riskRecord} />
              </div>
            </Widget>
          );
        })}
      </Layout.Stack>
    </ErrorHandler>
  );
};

Results.propTypes = {
  assets: PropTypes.shape({}),
  currentTarget: PropTypes.string,
  handleSearch: PropTypes.func,
  riskRecord: PropTypes.shape({
    ireRiskRecord: PropTypes.shape({
      remediationFindings: PropTypes.arrayOf(
        PropTypes.shape({
          attributeType: PropTypes.string,
        })
      ),
    }),
  }),
  targetRef: PropTypes.shape({
    current: PropTypes.instanceOf(PropTypes.element),
  }),
};

Results.defaultProps = {
  assets: {},
  currentTarget: '',
  handleSearch: () => {},
  riskRecord: {},
  targetRef: {},
};

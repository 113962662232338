import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Heading } from '@abyss/web/ui/Heading';
import { orderBy } from 'lodash';
import { Table as TableComponent } from '@src/components/Table-static';
import configuration from './configuration.json';

/**
 * RecordActivity
 *
 * This is for Records/Sources that are newly added or removed.  What info changed since the last time that IRE sent
 * the risk.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const RecordActivity = (props) => {
  const { addedRecords, removedRecords } = props;

  /**
   * renderCellAction
   *
   * displays the color coded action in a cell.
   *
   * @param cell
   * @returns {Element}
   */
  const renderCellAction = ({ cell }) => {
    if (cell?.value === 'Added') {
      return (
        <Badge outline variant="success">
          Added
        </Badge>
      );
    }

    if (cell?.value === 'Removed') {
      return (
        <Badge outline variant="error">
          Removed
        </Badge>
      );
    }

    return null;
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc']).map((item) => {
      const column = item;

      if (column.Header === 'Action') {
        column.Cell = renderCellAction;
      }

      return column;
    });
  }, []);

  /**
   * Rows for table.
   */
  const rows = useMemo(() => {
    return [
      ...addedRecords.map((record) => {
        return { ...record, action: 'Added' };
      }),
      ...removedRecords.map((record) => {
        return { ...record, action: 'Removed' };
      }),
    ];
  }, [addedRecords, removedRecords]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/TrustedValueHistory/components/Table/components/ExpansionRow/components/RecordActivity/RecordActivity.jsx">
      <Heading
        offset={5}
        css={{ marginBottom: 'var(--abyss-space-md) !important', marginTop: 'var(--abyss-space-sm)' }}
      >
        Record Activity
      </Heading>
      <TableComponent
        {...{
          accessor: 'eidSearch-trustedValueHistory-RecordActivity',
          columns,
          configuration,
          rows,
          noDataMessage: 'No records found.',
        }}
      />
    </ErrorHandler>
  );
};

RecordActivity.propTypes = {
  addedRecords: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
  removedRecords: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ),
};

RecordActivity.defaultProps = {
  addedRecords: [],
  removedRecords: [],
};

import React, { useEffect } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useToast } from '@abyss/web/hooks/useToast';
import { ContextProvider as WizardProvider } from './components/Wizard/context';
import { Wizard } from './components/Wizard';

/**
 * Manage
 *
 * Provides a step-by-step wizard interface for composing an action path.
 *
 * @returns {Element}
 * @constructor
 */
export const Manage = () => {
  const router = useRouter();
  const { status: actionPathStatus } = router?.getRouteParams();
  const { toast } = useToast();

  /**
   * Redirects the user to the list screen if the action path status is not "draft".
   */
  useEffect(() => {
    if (actionPathStatus !== 'draft') {
      toast.show({
        id: 'action-path-status-error',
        title: 'Invalid Status',
        message: (
          <React.Fragment>
            Only allowed to edit an action path with a <code>draft</code> status.
          </React.Fragment>
        ),
        variant: 'error',
      });

      router?.navigate('/action-paths');
    }
  }, [actionPathStatus]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/Manage/Manage.jsx">
      <WizardProvider>
        <Wizard />
      </WizardProvider>
    </ErrorHandler>
  );
};

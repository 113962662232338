import { styled } from '@abyss/web/tools/styled';

export const Styles = styled('div', {
  '.capitalize': {
    textTransform: 'capitalize',
  },
  '.abyss-button-root': {
    '.abyss-insert-element-before, .abyss-insert-element-after': {
      padding: 0,
    },
  },
  '.abyss-action-nav-root': {
    width: '100%',
    flexWrap: 'nowrap',
    '.abyss-text-input-root': {
      '.abyss-text-input-descriptors': {
        '&& > span': {
          position: 'absolute',
          top: 'calc(100% - 6px)',
          bottom: 'unset',
          marginRight: 'var(--abyss-space-lg)',
          padding: '4px',
          zIndex: '999',
          backgroundColor: 'var(--abyss-colors-error2)',
          border: '1px solid var(--abyss-colors-error1)',
        },
      },
    },
    '.abyss-action-nav-header': {
      maxWidth: '25%',
      width: '100%',
      flexGrow: 0,
      '.abyss-action-nav-header-card': {
        justifyContent: 'flex-start',
        flexGrow: 1,
        '.abyss-action-nav-text': {
          maxWidth: '100%',
          flexGrow: 1,
        },
      },
    },
    '.abyss-action-nav-list': {
      width: '100%',
      maxWidth: '75%',
      flexWrap: 'nowrap',
      flexGrow: 0,
      '.abyss-action-nav-list-item': {
        '.abyss-action-nav-link-container:before': {
          border: 'none',
        },
        'div:nth-of-type(2)': {
          width: '100%',
          height: '100%',
          '.abyss-action-nav-link-container': {
            width: '100%',
            height: '100%',
            margin: '0 calc(var(--abyss-space-lg) * -1)',
            alignItems: 'center',
            flexGrow: 1,
            justifyContent: 'center',
            cursor: 'auto !important',
            '.abyss-action-nav-link-wrapper': {
              margin: 0,
              '.abyss-flex-root': {
                flexWrap: 'nowrap',
                '.abyss-indicator-root': {
                  display: 'block',
                  '.abyss-indicator-container': {
                    position: 'relative',
                    top: 'unset',
                    left: 'unset',
                    transform: 'unset',
                    marginRight: 'var(--abyss-space-xs)',
                    borderColor: 'var(--abyss-colors-interactive1)',
                    backgroundColor: 'var(--abyss-colors-interactive1)',
                  },
                },
              },
            },
          },
        },
        '.abyss-icon:last-child': {
          display: 'none',
        },

        '.abyss-action-nav-chevron-after': {
          '&:before': {
            borderLeftColor: 'var(--abyss-colors-gray4)',
            left: 1,
          },
        },

        '&.current, &.completed': {
          borderColor: 'transparent',
          'div:nth-of-type(2)': {
            border: '1px solid var(--abyss-colors-interactive1)',
            backgroundColor: 'var(--abyss-colors-interactive1)',
            '.abyss-action-nav-link-container': {
              '&:before': {
                border: 'none',
              },
              '.abyss-action-nav-link-wrapper': {
                color: 'white',
                '.abyss-indicator-container': {
                  borderColor: '#ffffff !important',
                  backgroundColor: 'transparent',
                },
              },
              '.abyss-icon': {
                color: 'white',
              },
            },
          },
          '&.current': {
            '.abyss-action-nav-link-container': {
              cursor: 'auto !important',
            },
          },
          '&.disabled': {
            '.abyss-action-nav-link-container': {
              cursor: 'not-allowed !important',
              pointerEvents: 'all !important',
            },
          },
          '.abyss-action-nav-chevron-before': {
            borderColor: 'transparent',
            '&:before, &:after': {
              borderTopColor: 'var(--abyss-colors-interactive1)',
              borderBottomColor: 'var(--abyss-colors-interactive1)',
              borderRightColor: 'var(--abyss-colors-interactive1)',
            },
          },
          '.abyss-action-nav-chevron-after': {
            borderColor: 'var(--abyss-colors-interactive1)',
            '&:before': {
              left: 'auto',
            },
            '&:before, &:after': {
              borderLeftColor: 'var(--abyss-colors-interactive1)',
            },
          },
        },
        '.abyss-action-nav-header-text': {
          '.title': {
            fontSize: 'var(--abyss-fontSizes-lg)',
            fontWeight: 'var(--abyss-fontWeights-bold)',
            fontFamily: 'var(--abyss-fonts-primary)',
          },
          '.description': {
            fontSize: '12px',
            marginBottom: '0px',
            lineHeight: '16px',
            color: 'var(--abyss-colors-gray8)',
            fontWeight: 'var(--abyss-fontWeights-normal)',
          },
        },
      },
    },
  },
});

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { abbrNum } from '@src/includes/functions';
import { Button } from '@src/components/Button';
import { Checkbox } from '@abyss/web/ui/Checkbox';
import { config } from '@abyss/web/tools/config';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { LoadingSpinner } from '@abyss/web/ui/LoadingSpinner';
import { Modal } from '@abyss/web/ui/Modal';
import { useApi } from '@src/context/Api';
import { useCommonCriteria } from '@src/hooks/useCommonCriteria';
import { useCommonCriteriaVersions } from '@src/hooks/useCommonCriteriaVersions';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Styles } from './includes/styles';
import { WarningMessage } from './components/WarningMessage';

/**
 * ActivationModal
 *
 * Prompts the user with a popup window asking them if they wish to cancel or continue activating the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ActivationModal = (props) => {
  const { isOpen, setIsOpen, actionPath, setActionPath, handleActivation } = props;

  const [isChecked, setChecked] = useState(false);

  const router = useRouter();

  const { useApiQuery } = useApi();
  const [GetActionPathCount, { data: riskRecordCount, isLoading, isFetching, isFetched }] =
    useApiQuery('GetActionPathCount');

  const {
    isLoading: isCriteriaLoading,
    data: commonCriteria,
    fetch: fetchCriteria,
    criteriaList,
  } = useCommonCriteria();

  const {
    isLoading: isCriteriaVersionsLoading,
    data: commonCriteriaVersions,
    fetch: fetchCriteriaVersions,
    versionsList,
  } = useCommonCriteriaVersions();

  /**
   * retrieve common criteria saved to the action path
   */
  useEffect(() => {
    if (!isCriteriaLoading && isEmpty(commonCriteria) && !isUndefined(actionPath?.criteria)) {
      fetchCriteria(actionPath?.criteria);
    }
  }, [isCriteriaLoading, commonCriteria, actionPath]);

  /**
   * retrieve common criteria versions saved to the action path
   */
  useEffect(() => {
    if (!isCriteriaVersionsLoading && isEmpty(commonCriteriaVersions) && !isUndefined(actionPath?.criteria)) {
      fetchCriteriaVersions(actionPath?.criteria);
    }
  }, [isCriteriaVersionsLoading, commonCriteriaVersions, actionPath]);

  /**
   * fetch the number of records associated with the action path
   */
  useEffect(() => {
    if (!isFetched && isUndefined(riskRecordCount)) {
      GetActionPathCount({ actionPathId: actionPath?.id });
    }
  }, [isFetched, riskRecordCount]);

  /**
   * loop through criteriaList and versionsList to determine if the versions are a mismatch
   * @type {*[]}
   */
  const needsReview = useMemo(() => {
    const items = {
      entrance: [],
      exit: [],
    };

    criteriaList.forEach((criteria) => {
      const version = versionsList.find((item) => {
        return criteria?.id === item?.commonCriteriaId;
      });

      if (!isUndefined(version) && criteria?.activeVersionNbr !== version?.version) {
        if (criteria?.context.includes('entrance')) {
          items.entrance.push(criteria);
        }

        if (criteria?.context.includes('exit')) {
          items.exit.push(criteria);
        }
      }
    });

    return items;
  }, [criteriaList, versionsList]);

  /**
   * handleSave
   *
   * @TODO Needs description.
   *
   * @returns {Promise<void>}
   */
  const handleSave = async () => {
    try {
      setIsOpen(false);
      setActionPath({});
      await handleActivation(actionPath);
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/screens/List/components/ActivationModal/ActivationModal.jsx -> handleSave() -> error:',
        theError
      );

      throw error;
    }
  };

  /**
   * handleClose
   *
   * Highlights the row in the table for the action path which was requested to activate, but the user backed out. The
   * goal is to make it easy to identify which row in the table the user attempted to take action on, in-case they
   * change their mind and want to continue with the same one again.
   *
   * @returns {Promise<void>}
   */
  const handleClose = async () => {
    try {
      setIsOpen(false);
      setActionPath({});
      router?.navigate(`/`);
      router?.navigate(`/action-paths?highlight=${actionPath?.id}`);
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/screens/List/components/ActivationModal/ActivationModal.jsx -> handleClose() -> error:',
        theError
      );

      throw error;
    }
  };
  let count = riskRecordCount;

  if (riskRecordCount > 0) {
    count = abbrNum(riskRecordCount);
  }

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/List/components/ActivationModal/ActivationModal.jsx">
      <Modal
        title="Confirm Activation"
        isOpen={isOpen}
        onClose={handleClose}
        footer={
          <Modal.Section>
            <Divider height={1} />
            <Layout.Group alignLayout="right">
              <Button variant="outline" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="solid"
                onClick={handleSave}
                isDisabled={
                  isLoading ||
                  isFetching ||
                  isNull(riskRecordCount) ||
                  (riskRecordCount === 0 && !isChecked) ||
                  !isEmpty(needsReview)
                }
              >
                Activate
              </Button>
            </Layout.Group>
          </Modal.Section>
        }
      >
        <Styles>
          <Modal.Section>
            {!isEmpty(needsReview) && (
              <React.Fragment>
                {needsReview?.entrance?.map((theCriteria) => {
                  return <WarningMessage actionPath={actionPath} criteria={theCriteria} context="entrance" />;
                })}
                {needsReview?.exit?.map((theCriteria) => {
                  return <WarningMessage actionPath={actionPath} criteria={theCriteria} context="exit" />;
                })}
              </React.Fragment>
            )}

            <Layout.Group>
              <div>The number of records associated with this Action Path is:</div>
              <div>
                <strong>
                  {isLoading || isFetching || isNull(count) ? (
                    <LoadingSpinner size="$sm" isLoading ariaLoadingLabel="Loading..." />
                  ) : (
                    <React.Fragment>{count}</React.Fragment>
                  )}
                </strong>
              </div>
            </Layout.Group>

            {count === 0 && (
              <Checkbox
                label="Continue with 0 records"
                isChecked={isChecked}
                onChange={(event) => {
                  return setChecked(event?.target?.checked);
                }}
              />
            )}
          </Modal.Section>
        </Styles>
      </Modal>
    </ErrorHandler>
  );
};

ActivationModal.propTypes = {
  actionPath: PropTypes.shape({
    criteria: PropTypes.shape({
      entrance: PropTypes.shape({
        commonIds: PropTypes.arrayOf(PropTypes.string),
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    id: PropTypes.string,
  }),
  handleActivation: PropTypes.func,
  isOpen: PropTypes.bool,
  setActionPath: PropTypes.func,
  setIsOpen: PropTypes.func,
};

ActivationModal.defaultProps = {
  actionPath: {
    criteria: {
      entrance: {
        commonIds: [],
        commonCriteriaVersionsIds: [],
      },
    },
    id: '',
  },
  handleActivation: () => {},
  isOpen: false,
  setActionPath: () => {},
  setIsOpen: () => {},
};

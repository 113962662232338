/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ActionPathNotes } from '@src/common/widgets/ActionPathNotes';
import { AnalyzeRisksButton } from '@src/components/AnalyzeRisksButton';
import { AssociatedEids } from '@src/common/widgets/AssociatedEids';
import { Button } from '@src/components/Button';
import { config } from '@abyss/web/tools/config';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FilterCriteria } from '@src/common/widgets/FilterCriteria';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isEmpty, isUndefined } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { motion } from 'framer-motion';
import { RemediationActions } from '@src/common/widgets/RemediationActions';
import { Text } from '@abyss/web/ui/Text';
import { useApi } from '@src/context/Api';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { useToast } from '@abyss/web/hooks/useToast';
import { Styles } from './includes/styles';
import { SourceRecordsRemediated } from './widgets/SourceRecordsRemediated';
import { Skeleton } from './components/Skeleton';
import { RecordAgeInPath } from './widgets/RecordAgeInPath';
import { ModeBadge } from '../../components/ModeBadge';
import { InQueue } from './widgets/InQueue';
import { ActionTakenCodes } from './widgets/ActionTakenCodes';
import { ActionStatusCodes } from './widgets/ActionStatusCodes';

/**
 * View
 *
 * Provides the user with a screen to view information about the action path.
 *
 * @returns {Element}
 * @constructor
 */
export const View = () => {
  const { currentRoute } = useRoutesContext();
  const actionPathId = currentRoute?.params?.id;
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [hasCipSource, setHasCipSource] = useState(false);
  const [isReEvaluateButtonDisabled, setIsReEvaluateButtonDisabled] = useState(false);

  const { toast } = useToast();

  const { useApiQuery, useApiQueries, useApiMutation, clearApiCache } = useApi();

  const [GetActionPathTrackerStatus, { data: actionPathTrackerStatus }] = useApiQuery('GetActionPathTrackerStatus');
  const [ReEvaluateActionPath] = useApiMutation('ReEvaluateActionPath');

  const theQueries = useMemo(() => {
    if (isUndefined(actionPathId)) {
      return [];
    }
    return [
      {
        key: 'GetActionPath',
        args: { actionPathId },
      },
      {
        key: 'GetActionPathStatistics',
        args: { actionPathId },
      },
    ];
  }, [actionPathId]);

  const queries = useApiQueries(theQueries);

  const actionPath = queries?.GetActionPath?.data;
  const actionPathStatistics = queries?.GetActionPathStatistics?.data;

  /**
   * Fetch the action path tracker status if it hasn't been loaded yet.
   */
  useLayoutEffect(() => {
    if (isUndefined(actionPathTrackerStatus) && actionPath?.manualAssociation === false) {
      GetActionPathTrackerStatus({ actionPathId: actionPath?.id });
    }
  }, [actionPath, actionPathTrackerStatus]);

  /**
   * handleReEvaluation
   *
   * Handle the re-evaluation of the action path.
   *
   * @returns {Promise<void>}
   */
  const handleReEvaluation = async () => {
    try {
      const toastId = 'ReEvaluateActionPath';

      toast.show({
        autoClose: true,
        id: `${toastId}-info`,
        isPending: true,
        message: `The action path is preparing to schedule a re-evaluation.`,
        title: `Scheduling Action Path Re-Evaluation...`,
        variant: 'info',
      });

      setIsReEvaluateButtonDisabled(true);

      await ReEvaluateActionPath(
        {
          actionPathId,
        },
        {
          onSuccess: () => {
            toast.hide(`${toastId}-info`);
            toast.show({
              id: `${toastId}-success`,
              title: `Action Path Re-Evaluation Scheduled`,
              message: 'Action Path has been scheduled for re-evaluation.',
              variant: 'success',
            });
            clearApiCache(['GetActionPathTrackerStatus']);
            setIsReEvaluateButtonDisabled(true);
          },
          onError: () => {
            toast.hide(`${toastId}-info`);
            toast.show({
              id: `${toastId}-error`,
              title: `Failed to Re-Evaluate Action Path`,
              message: `There was an error re-evaluating the action path.`,
              variant: 'error',
            });
            setIsReEvaluateButtonDisabled(false);
          },
        }
      );
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error('src/routes/private/ActionPaths/screens/View/View.jsx -> handleReEvaluation():', theError);
    }
  };

  /**
   * Determines the overall loading state of all queries.
   */
  useEffect(() => {
    if (
      !isEmpty(queries) &&
      Object.keys(queries).length === theQueries.length &&
      isEmpty(
        Object.keys(queries).filter((queryKey) => {
          const query = queries[queryKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [queries, theQueries]);

  /**
   * Redirect the user back to the list screen if action path status is not "ACTIVE"
   */
  useLayoutEffect(() => {
    if (!isEmpty(actionPath) && actionPath?.status !== 'ACTIVE') {
      router?.navigate('/action-paths');
      toast.show({
        id: 'action-path-status-error',
        title: 'Invalid Status',
        message: (
          <React.Fragment>
            Not allowed to view action path with status other than <code>active</code>.
          </React.Fragment>
        ),
        variant: 'error',
      });
    }
  }, [actionPath]);

  /**
   * Disable the re-evaluate button if the action path tracker status is not complete.
   */
  useEffect(() => {
    if (!isUndefined(actionPathTrackerStatus) && actionPathTrackerStatus?.actionPathTrackerStatus !== 'COMPLETE') {
      setIsReEvaluateButtonDisabled(true);
    }
  }, [actionPathTrackerStatus?.actionPathTrackerStatus]);

  /**
   * Determine if there are any action assignments with an impacted source of CIP.
   */
  useEffect(() => {
    const hasCip = actionPath?.assignments?.some((assignment) => {
      return assignment?.impactedSource === 'CIP';
    });

    if (hasCip !== hasCipSource) {
      setHasCipSource(hasCip);
    }
  }, [actionPath]);

  if (isLoading) {
    return <Skeleton hasCipSource={hasCipSource} />;
  }

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/View/View.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <Styles>
          <Grid>
            <Grid.Col css={{ paddingTop: 'var(--abyss-space-lg)' }} span={{ xs: '100%' }}>
              <Heading offset={0}>
                <Layout.Group>
                  <div>Action Path: {actionPath?.name}</div>
                  <div>
                    <ModeBadge actionPath={actionPath} />
                  </div>
                </Layout.Group>
              </Heading>
              <Text color="var(--abyss-colors-gray6)">
                Last Updated:{' '}
                <time dateTime={actionPathStatistics?.date} style={{ color: 'black' }}>
                  {dayjs(actionPathStatistics?.date).format('dddd, MMMM D, YYYY h:mm A')} CST
                </time>
              </Text>
            </Grid.Col>
            <Grid.Col span={{ xs: '100%' }}>
              <Layout.Group>
                <AnalyzeRisksButton actionPath={actionPath} variant="button" />
                {actionPath?.manualAssociation === false && (
                  <Button
                    variant="solid"
                    before={<IconSymbol icon="refresh" variant="outlined" />}
                    onClick={async () => {
                      await handleReEvaluation();
                    }}
                    isDisabled={isReEvaluateButtonDisabled}
                  >
                    Re-Evaluate
                  </Button>
                )}
              </Layout.Group>
            </Grid.Col>
          </Grid>

          <Grid className="flexGrid">
            {hasCipSource
              ? [
                  <Grid.Col
                    key="cip-InQueue"
                    css={{ paddingTop: 'var(--abyss-space-lg)' }}
                    span={{ xs: '33.33%', sm: '33.33%', md: '33.33%', lg: '33.33%' }}
                    className="grow"
                  >
                    <InQueue statusCodeCounts={actionPathStatistics?.statusCodeCounts} />
                  </Grid.Col>,
                  <Grid.Col
                    key="cip-ActionStatusCodes"
                    css={{ paddingTop: 'var(--abyss-space-lg)' }}
                    span={{ xs: '33.33%', sm: '33.33%', md: '33.33%', lg: '33.33%' }}
                    className="grow"
                  >
                    <ActionStatusCodes
                      recordReferenceStatusCodeCounts={actionPathStatistics?.recordReferenceStatusCodeCounts}
                    />
                  </Grid.Col>,
                  <Grid.Col
                    key="cip-ActionTakenCodes"
                    css={{ paddingTop: 'var(--abyss-space-lg)' }}
                    span={{ xs: '33.33%', sm: '33.33%', md: '33.33%', lg: '33.33%' }}
                    className="grow"
                  >
                    <ActionTakenCodes actionTakenCounts={actionPathStatistics?.actionTakenCounts.actionTakenStats} />
                  </Grid.Col>,
                ]
              : [
                  <Grid.Col
                    key="InQueue"
                    css={{ paddingTop: 'var(--abyss-space-lg)' }}
                    span={{ xs: '33.33%', sm: '33.33%', md: '33.33%', lg: '33.33%' }}
                  >
                    <InQueue statusCodeCounts={actionPathStatistics?.statusCodeCounts} />
                  </Grid.Col>,

                  <Grid.Col
                    key="stats"
                    css={{ paddingTop: 'var(--abyss-space-lg)' }}
                    span={{ xs: '66.66%', sm: '66.66%', md: '66.66%', lg: '66.66%' }}
                  >
                    <Layout.Stack alignLayout="left" alignItems="top" grow space={themeConfiguration?.theme?.space?.lg}>
                      <SourceRecordsRemediated data={actionPathStatistics?.remediationStats || {}} />
                      <RecordAgeInPath data={actionPathStatistics?.recordAgeStats || {}} />
                    </Layout.Stack>
                  </Grid.Col>,
                ]}
          </Grid>

          <Grid>
            {actionPath?.manualAssociation === false && [
              <Grid.Col
                css={{ paddingTop: 'var(--abyss-space-lg)' }}
                span={{
                  xs: '50%',
                }}
              >
                <FilterCriteria
                  {...{
                    filterKey: 'entranceCriteria',
                    criteria: actionPath?.criteria,
                    context: 'entrance',
                  }}
                />
              </Grid.Col>,
              <Grid.Col
                css={{ paddingTop: 'var(--abyss-space-lg)' }}
                span={{
                  xs: '50%',
                }}
              >
                <FilterCriteria
                  {...{
                    filterKey: 'exitCriteria',
                    criteria: actionPath?.criteria,
                    context: 'exit',
                    criteriaMethod: actionPath?.exitCriteriaMethod,
                  }}
                />
              </Grid.Col>,
            ]}

            {actionPath?.manualAssociation === true && (
              <Grid.Col
                css={{ paddingTop: 'var(--abyss-space-lg)' }}
                span={{
                  xs: '50%',
                }}
              >
                <AssociatedEids actionPath={actionPath} />
              </Grid.Col>
            )}

            <Grid.Col
              css={{ paddingTop: 'var(--abyss-space-lg)' }}
              span={{
                xs: actionPath?.manualAssociation === true ? '50%' : '100%',
              }}
            >
              <RemediationActions
                assignments={actionPath?.assignments}
                scopeCode={actionPath?.actionPathScopeCode}
                remediationType={actionPath?.remediationMethod}
              />
            </Grid.Col>
          </Grid>

          {hasCipSource && (
            <Grid>
              <Grid.Col
                css={{ paddingTop: 'var(--abyss-space-lg)' }}
                span={{
                  xs: '50%',
                }}
              >
                <SourceRecordsRemediated data={actionPathStatistics?.remediationStats || {}} />
              </Grid.Col>
              <Grid.Col
                css={{ paddingTop: 'var(--abyss-space-lg)' }}
                span={{
                  xs: '50%',
                }}
              >
                <RecordAgeInPath data={actionPathStatistics?.recordAgeStats || {}} />
              </Grid.Col>
            </Grid>
          )}

          <Grid>
            <Grid.Col
              css={{ paddingTop: 'var(--abyss-space-lg)' }}
              span={{
                xs: '50%',
              }}
            >
              <ActionPathNotes actionPath={actionPath} />
            </Grid.Col>
          </Grid>
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
};

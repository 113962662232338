import PropTypes from 'prop-types';
import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Divider } from '@abyss/web/ui/Divider';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { motion } from 'framer-motion';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';

/**
 * Skeleton
 *
 * Displays a roughed-in idea/placeholder of what the UI should look like while the data is fetching from the API.
 *
 * @returns {Element}
 * @constructor
 */
export const Skeleton = (props) => {
  const { hasCipSource } = props;

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/View/components/Skeleton/Skeleton.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <Grid>
          <Grid.Col
            css={{ paddingRight: 0 }}
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.xl} width="50%" />
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="30%" />
            </Layout.Stack>
          </Grid.Col>
        </Grid>
        <Divider color="transparent" />
        <Grid className="flexGrid">
          {hasCipSource
            ? [
                <Grid.Col
                  key="cip-InQueue"
                  css={{ paddingTop: 'var(--abyss-space-lg)' }}
                  span={{ xs: '33.33%', sm: '33.33%', md: '33.33%', lg: '33.33%' }}
                  className="grow"
                >
                  <AbyssSkeleton height={575} />
                </Grid.Col>,
                <Grid.Col
                  key="cip-ActionStatusCodes"
                  css={{ paddingTop: 'var(--abyss-space-lg)' }}
                  span={{ xs: '33.33%', sm: '33.33%', md: '33.33%', lg: '33.33%' }}
                  className="grow"
                >
                  <AbyssSkeleton height={575} />
                </Grid.Col>,

                <Grid.Col
                  key="cip-ActionTakenCodes"
                  css={{ paddingTop: 'var(--abyss-space-lg)' }}
                  span={{ xs: '33.33%', sm: '33.33%', md: '33.33%', lg: '33.33%' }}
                  className="grow"
                >
                  <AbyssSkeleton height={575} />
                </Grid.Col>,
              ]
            : [
                <Grid.Col
                  key="InQueue"
                  css={{ paddingTop: 'var(--abyss-space-lg)' }}
                  span={{ xs: '33.33%', sm: '33.33%', md: '33.33%', lg: '33.33%' }}
                >
                  <AbyssSkeleton height="100%" />
                </Grid.Col>,

                <Grid.Col
                  key="stats"
                  css={{ paddingTop: 'var(--abyss-space-lg)' }}
                  span={{ xs: '66.66%', sm: '66.66%', md: '66.66%', lg: '66.66%' }}
                >
                  <div style={{ width: '100%' }}>
                    <Layout.Stack alignItems="left" grow space={themeConfiguration?.theme?.space?.md}>
                      <AbyssSkeleton height={250} />
                      <AbyssSkeleton height={250} />
                    </Layout.Stack>
                  </div>
                </Grid.Col>,
              ]}
        </Grid>

        <Divider color="transparent" />
        <Grid>
          <Grid.Col
            css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
            span={{
              xs: '100%',
            }}
          >
            <Layout.Group alignItems="left" grow space={themeConfiguration?.theme?.space?.md}>
              <AbyssSkeleton height={400} />
              <AbyssSkeleton height={400} />
            </Layout.Group>
          </Grid.Col>
        </Grid>

        {hasCipSource && (
          <Grid>
            <Grid.Col
              css={{ paddingTop: 'var(--abyss-space-lg)' }}
              span={{
                xs: '50%',
              }}
            >
              <AbyssSkeleton height={250} />{' '}
            </Grid.Col>
            <Grid.Col
              css={{ paddingTop: 'var(--abyss-space-lg)' }}
              span={{
                xs: '50%',
              }}
            >
              <AbyssSkeleton height={250} />{' '}
            </Grid.Col>
          </Grid>
        )}

        <Grid.Col
          css={{ paddingTop: themeConfiguration?.theme?.space?.lg }}
          span={{
            xs: '50%',
          }}
        >
          <AbyssSkeleton height={400} />
        </Grid.Col>
      </motion.div>
    </ErrorHandler>
  );
};

Skeleton.propTypes = {
  hasCipSource: PropTypes.bool,
};

Skeleton.defaultProps = {
  hasCipSource: false,
};

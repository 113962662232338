import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Modal } from '@abyss/web/ui/Modal';
import { CreateForm } from '../../forms/Create';
import { useSave } from '../../../hooks/useSave';

/**
 * Modal: Creation
 *
 * This modal is used to create a new set of common criteria filters.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Creation = (props) => {
  const { assets, isOpen, setIsOpen, refetch, setFocusedEntity } = props;

  const handleSave = useSave('create');

  /**
   * handleClose
   *
   * Handles the closing of the modal.
   *
   * @returns {Promise<void>}
   */
  const handleClose = () => {
    setIsOpen(false);
  };

  /**
   * handleCreate
   *
   * Saves the
   *
   * @param payload
   * @returns {Promise<void>}
   */
  const handleCreate = async (payload = {}) => {
    await handleSave(payload, refetch);
    setIsOpen(false);
  };

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/modals/Creation/Creation.jsx">
      <Modal title="Create Common Criteria" isOpen={isOpen} onClose={handleClose} size="full">
        <Modal.Section>
          <CreateForm
            assets={assets}
            handleSave={handleCreate}
            handleClose={handleClose}
            setFocusedEntity={setFocusedEntity}
          />
        </Modal.Section>
      </Modal>
    </ErrorHandler>
  );
};

Creation.propTypes = {
  assets: PropTypes.shape({
    commonCriteria: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
    riskTypes: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
      })
    ),
  }),
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
  refetch: PropTypes.func,
  setFocusedEntity: PropTypes.func,
};

Creation.defaultProps = {
  assets: {},
  isOpen: false,
  setIsOpen: () => {},
  refetch: () => {},
  setFocusedEntity: () => {},
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { isNull, orderBy } from 'lodash';
import { Table as TableComponent } from '@src/components/Table-static';
import configuration from './includes/configuration.json';

/**
 * Records
 *
 * Data containing trusted and unstable records from EIMP.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Records = (props) => {
  const { rows } = props;

  /**
   * renderCellIcon
   *
   * Displays an icon to distinguish between trusted and untrusted records.
   *
   * @returns {Element}
   */
  const renderCellIcon = ({ row }) => {
    if (row?.original?.status === 'trusted') {
      return <IconSymbol icon="check_circle" variant="outlined" color="var(--abyss-colors-success1)" />;
    }

    if (row?.original?.status === 'untrusted') {
      return <IconSymbol icon="error" variant="outlined" color="var(--abyss-colors-error1)" />;
    }

    return null;
  };

  /**
   * renderCellDobLastModified
   *
   * Displays the date of birth last modified in a particular date/time format.
   *
   * @param value
   * @returns {*|string}
   */
  const renderCellDobLastModified = ({ value }) => {
    if (isNull(value)) {
      return '';
    }

    return dayjs(value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * renderCellDate
   *
   * Displays the date in a particular date format.
   *
   * @param value
   * @returns {*|string}
   */
  const renderCellDate = ({ value }) => {
    if (isNull(value)) {
      return '';
    }

    return dayjs(value).format('MM/DD/YYYY');
  };

  /**
   * Columns for the table.
   */
  const columns = useMemo(() => {
    return orderBy(configuration?.initialColumns, ['order'], ['asc'])?.map((column) => {
      const theColumn = column;

      if (theColumn?.accessor === 'status') {
        theColumn.Cell = renderCellIcon;
      }

      if (theColumn?.Header === 'DOB Last Modified') {
        theColumn.Cell = renderCellDobLastModified;
      }

      if (['Coverage End', 'Coverage Last Modified', 'HCM Patient Created'].includes(theColumn?.Header)) {
        theColumn.Cell = renderCellDate;
      }

      return theColumn;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/Eimp/components/Records/Records.jsx">
      <p>
        <strong>Untrusted and Trusted Sources</strong>
      </p>
      <TableComponent
        {...{
          columns,
          rows,
          configuration,
          dataKey: `EidSearch-Table-Eimp`,
        }}
      />
    </ErrorHandler>
  );
};

Records.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      status: PropTypes.string,
      dob: PropTypes.string,
      dobLastModified: PropTypes.string,
      coverageEnd: PropTypes.string,
      coverageLastModified: PropTypes.string,
      hcmPatientCreated: PropTypes.string,
    })
  ),
};

Records.defaultProps = {
  rows: [],
};

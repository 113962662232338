import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isNil, isUndefined } from 'lodash';
import { translateAssignments, translateCriteria } from '@src/routes/private/ActionPaths/includes/functions';

/**
 * SaveActionPath
 *
 * Saves a single action path with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveActionPath = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/action-paths',
      },
      params: { mutationKey: theMutationKey },
      data: {},
    };

    if (!isUndefined(thePayload?.id) && !isEmpty(thePayload?.id)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}`;
    }

    requestArgs.data.name = thePayload?.name;
    requestArgs.data.actionPathScopeCode = thePayload?.remediation?.actionPathScopeCode;
    requestArgs.data.remediationMethod = thePayload?.remediation?.remediationMethod;
    requestArgs.data.note = thePayload?.remediation?.notes;
    requestArgs.data.manualAssociation = thePayload?.manualAssociation;
    requestArgs.data.exitCriteriaMethod = thePayload?.exitCriteriaMethod;
    requestArgs.data.status = thePayload?.status;

    delete thePayload?.criteria?.entrance?.mergedStr;
    delete thePayload?.criteria?.exit?.mergedStr;

    if (!isNil(thePayload?.criteria)) {
      requestArgs.data.criteria = translateCriteria(thePayload?.criteria, 'outgoing');
    }

    if (!isNil(thePayload?.remediation?.assignments)) {
      requestArgs.data.assignments = translateAssignments(thePayload?.remediation?.assignments, 'outgoing');
    }

    const remoteResponse = await Axios.request(requestArgs);

    if (!isNil(remoteResponse?.data?.criteria)) {
      remoteResponse.data.criteria = translateCriteria(remoteResponse?.data?.criteria, 'incoming');
    }

    if (!isNil(remoteResponse?.data?.assignments)) {
      remoteResponse.data.assignments = translateAssignments(remoteResponse?.data?.assignments, 'incoming');
    }

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveActionPath.js -> SaveActionPath() -> error:', theError);

    throw error;
  }
};

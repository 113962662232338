import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isNil, omit } from 'lodash';
import { translateCriteriaFilters } from '@src/routes/private/ActionPaths/includes/functions';

/**
 * SaveCommonCriteria
 *
 * Saves a set of common criteria filter with the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<{}>}
 * @constructor
 */
export const SaveCommonCriteria = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': '/criteria',
      },
      params: { mutationKey: theMutationKey },
      data: {},
    };

    let criteria = [];

    if (!isNil(thePayload?.filters)) {
      criteria = translateCriteriaFilters(thePayload?.filters, 'outgoing');
    }

    if (thePayload?.action === 'saveName') {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}`;
      requestArgs.data = omit(thePayload, ['filters', 'action']);
    }

    if (thePayload?.action === 'createVersion') {
      requestArgs.method = 'POST';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}/version`;
      requestArgs.data = { criteria };
    }

    if (['activate', 'deactivate', 'update'].includes(thePayload?.action)) {
      requestArgs.method = 'PUT';
      requestArgs.headers['x-api-endpoint'] += `/${thePayload?.id}`;
      requestArgs.data = omit(thePayload, ['action', 'filters', 'version']);
    }

    if (thePayload?.action === 'update') {
      requestArgs.data.activeCommonCriteriaVersion = { criteria };
      requestArgs.data.activeVersionNbr = thePayload?.version;
    }

    if (thePayload?.action === 'create') {
      requestArgs.data = omit(thePayload, ['id', 'filters', 'action']);
      requestArgs.data.criteria = criteria;
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/SaveCommonCriteria.js -> SaveCommonCriteria() -> error:', theError);

    throw error;
  }
};

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Data containing sources and record ids for trusted and untrusted values.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows, type } = props;

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header.includes('Source')) {
        if (type === 'trusted') {
          column.Header = 'Trusted Source';
        } else {
          column.Header = 'Untrusted Source';
        }
      }

      return column;
    });
  }, [type]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/Attribute/components/Table/Table.jsxx">
      <TableComponent
        {...{
          columns,
          rows,
          configuration,
          dataKey: `EidSearch-Attribute-Table-${type}`,
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      source: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  type: PropTypes.string,
};

Table.defaultProps = {
  rows: [],
  type: '',
};

import { config } from '@abyss/web/tools/config';
import { useApi } from '@src/context/Api';
import { useCallback } from 'react';
import { useToast } from '@abyss/web/hooks/useToast';

/**
 * useSave
 *
 * Makes an API request to save the action path.
 *
 * @returns {(function({}=, function()=): Promise<void>)|*}
 */
export const useSave = () => {
  const { useApiMutation, clearApiCache } = useApi();

  const [SaveActionPath, mutation] = useApiMutation('SaveActionPath');

  const { toast } = useToast();

  const mutate = useCallback(async (submittedValues = {}) => {
    let response = {};
    try {
      const toastId = `action-paths`;

      toast.show({
        id: `${toastId}-info`,
        title: `Saving Action Path`,
        message: 'Preparing to save the Action Path.',
        isLoading: true,
        variant: 'info',
        autoClose: false,
      });

      const payload = {
        ...submittedValues,
      };

      const remoteResponse = await SaveActionPath(payload, {
        onSuccess: async () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-success`,
            title: `Saved Action Path`,
            message: 'Successfully saved the Action Path.',
            variant: 'success',
          });
        },
        onError: () => {
          toast.hide(`${toastId}-info`);
          toast.show({
            id: `${toastId}-error`,
            title: `Error Saving Action Path`,
            message: 'An error occurred while saving the Action Path.',
            variant: 'error',
          });
        },
      });

      clearApiCache([
        'ListActionPaths',
        'ListCommonCriteria',
        'GetActionPath',
        'GetCommonCriteria',
        'GetCommonCriteriaVersion',
      ]);

      response = remoteResponse;
    } catch (error) {
      let theError = error;

      if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
        theError = JSON.stringify(error);
      }

      console.error(
        'src/routes/private/ActionPaths/screens/Manage/components/Wizard/hooks/useSave/useSave.js:',
        theError
      );
    }
    return response;
  }, []);

  return { handleSave: mutate, isSaving: mutation?.isPending };
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';

/**
 * RefreshEIMP
 *
 * Refresh EIMP data by putting the EID onto the stream for re-processing
 *
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const RefreshEIMP = async (payload = {}) => {
  try {
    const theMutationKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'PUT',
      headers: {
        'x-api-endpoint': `/eimp/${thePayload?.eidId}/refresh`,
      },
      params: { mutationKey: theMutationKey },
      data: {},
    };

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/mutations/RefreshEIMP.js -> RefreshEIMP() -> error:', theError);

    throw error;
  }
};

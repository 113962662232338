import React from 'react';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { motion } from 'framer-motion';
import { Skeleton as AbyssSkeleton } from '@abyss/web/ui/Skeleton';

/**
 * Skeleton
 *
 * @returns {Element}
 * @constructor
 */
export const Skeleton = () => {
  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Skeleton/Skeleton.jsx">
      <motion.div
        style={{ width: '100%' }}
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <Grid>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="15%" />
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="30%" />
              <AbyssSkeleton height="300px" width="100%" />
            </Layout.Stack>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="15%" />
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="30%" />
              <AbyssSkeleton height="300px" width="100%" />
            </Layout.Stack>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <Layout.Stack alignItems="left" grow>
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="15%" />
              <AbyssSkeleton height={themeConfiguration?.theme?.space?.lg} width="30%" />
              <AbyssSkeleton height="300px" width="100%" />
            </Layout.Stack>
          </Grid.Col>
        </Grid>
      </motion.div>
    </ErrorHandler>
  );
};

import PropTypes from 'prop-types';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEmpty, isFunction, isObject, isString, isUndefined, merge } from 'lodash';
import {
  keepPreviousData,
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQueries,
  useQuery,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { requests } from '@src/requests';
import { useToast } from '@abyss/web/hooks/useToast';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 300000, // 5 minutes - Returns cached response. Triggers API request in background, updating cache.
      placeholderData: keepPreviousData,
      refetchInterval: 0,
      refetchIntervalInBackground: false,
      refetchOnMount: true, // the query will refetch on mount if the data is stale.
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      retry: 1, // retries 1 time on failure
      retryDelay: 500, // retries half a second after failure
      retryOnMount: true, // retry on mount if the query fails,
      staleTime: 15000, // 15 seconds - Returns cached response. Prevents any additional API requests,
      throwOnError: false,
    },
    mutations: {
      gcTime: Infinity, // disables cache
      retry: false, // Do not retry on failure, otherwise specify integer for number of retries if request fails.
      retryDelay: 0,
      throwOnError: false,
    },
  },
});

/**
 *
 * @type {React.Context<{queryClient: {}, clearApiCache: *, mutations: {}, useApiQuery: *, useApiMutation: *,
 *   useApiQueries: *, queries: {}}>}
 */
const ApiContext = createContext({
  clearApiCache: () => {},
  mutations: {},
  queries: {},
  queryClient: {},
  useApiMutation: () => {},
  useApiQueries: () => {},
  useApiQuery: () => {},
});

export const useApi = () => {
  return useContext(ApiContext);
};

/**
 * useApiMutation
 *
 * Dynamic api request function based on mutation key to make on-demand api mutations.
 *
 * @param mutationKey
 * @param options
 * @returns {*[]}
 */
function useApiMutation(mutationKey = '', options = {}) {
  const { toast } = useToast();

  let mutationFunction;

  if (!isUndefined(requests?.mutations?.[mutationKey])) {
    mutationFunction = requests?.mutations?.[mutationKey];
  }

  const theMutation = merge(
    {},
    {
      mutationKey: [mutationKey],
      mutationFn: async (variables) => {
        try {
          let response;

          if (isUndefined(mutationFunction)) {
            throw new Error(`Request not found for mutation key: ${mutationKey}`);
          }

          if (isFunction(mutationFunction)) {
            response = await mutationFunction([mutationKey, variables]);
          }

          return response;
        } catch (error) {
          const excludedHttpCodes = options?.excludedHttpCodes || [];

          if (!excludedHttpCodes?.includes(error?.response?.data?.error?.code)) {
            toast.show({
              id: `useApiMutation-${mutationKey}`,
              title: `API Error - ${mutationKey}`,
              message: error?.message,
              variant: 'error',
              autoClose: false,
            });
          }

          console.error('src/context/Api/Api.jsx -> useApiMutation -> error:', mutationKey, error);
          throw error;
        }
      },
      excludedHttpCodes: [],
    },
    options
  );

  const mutation = useMutation(theMutation);

  return [mutation?.mutateAsync, mutation];
}

/**
 * useApiQuery
 *
 * Dynamic api request function based on query key to make on-demand api requests.
 *
 * @param queryKey
 * @param options
 * @returns {*[]}
 */
function useApiQuery(queryKey = '', options = {}) {
  const [enabled, setEnabled] = useState(false);
  const [queryArgs, setQueryArgs] = useState({});
  const { toast } = useToast();

  let queryFunction;

  if (!isUndefined(requests?.queries?.[queryKey])) {
    queryFunction = requests?.queries?.[queryKey];
  }

  const theQuery = merge(
    {},
    {
      queryKey: [queryKey, queryArgs],
      queryFn: async (args) => {
        try {
          let response;
          if (isUndefined(queryFunction)) {
            throw new Error(`Request not found for query key: ${queryKey}`);
          }

          if (isFunction(queryFunction)) {
            response = await queryFunction(args.queryKey);
          }

          setEnabled(false);
          return response;
        } catch (error) {
          setEnabled(false);

          const excludedHttpCodes = options?.excludedHttpCodes || [];

          if (!excludedHttpCodes?.includes(error?.response?.data?.error?.code)) {
            toast.show({
              id: `useApiQuery-${queryKey}`,
              title: `API Error - ${queryKey}`,
              message: error?.message,
              variant: 'error',
              autoClose: false,
            });
          }

          console.error('src/context/Api/Api.jsx -> useApiQuery -> error:', queryKey, error);
          throw error;
        }
      },
      enabled,
      excludedHttpCodes: [],
    },
    options
  );

  const query = useQuery(theQuery);

  /**
   * requestFunction
   *
   * Sets a state value to define request args and enable the query to make a fetch request with those args.
   *
   * @param args
   * @returns {Promise<void>}
   */
  const requestFunction = (args) => {
    setQueryArgs(args);
    setEnabled(true);
  };

  return [requestFunction, query];
}

/**
 * useApiQueries
 *
 * Make multiple api requests in parallel.
 *
 * @param queries
 * @returns {{}}
 */
function useApiQueries(queries = []) {
  const { toast } = useToast();

  const theQueries = useQueries({
    queries: queries.map((query) => {
      const queryKey = [];

      if (isString(query)) {
        queryKey.push(query);
      }

      if (isObject(query)) {
        queryKey.push(query?.key);
        queryKey.push(query?.args);
      }

      let requestKey = queryKey?.[0];
      if (queryKey?.[0].includes('-')) {
        const keyItems = queryKey?.[0].split('-');
        requestKey = keyItems?.[0];
      }

      let queryFunction;

      if (!isUndefined(requests?.queries?.[requestKey])) {
        queryFunction = requests?.queries?.[requestKey];
      }

      return merge(
        {},
        {
          queryKey,
          queryFn: async (args) => {
            try {
              let response;

              if (isUndefined(queryFunction)) {
                throw new Error(`Request not found for query key: ${queryKey[0]}`);
              }

              if (isFunction(queryFunction)) {
                response = await queryFunction(args.queryKey);
              }

              return {
                [queryKey[0]]: response,
              };
            } catch (error) {
              const excludedHttpCodes = query?.options?.excludedHttpCodes || [];

              if (!excludedHttpCodes?.includes(error?.response?.data?.error?.code)) {
                toast.show({
                  id: `useApiQueries-${queryKey[0]}`,
                  title: `API Error - ${queryKey[0]}`,
                  message: error?.message,
                  variant: 'error',
                  autoClose: false,
                });
              }

              console.error('src/context/Api/Api.jsx -> useApiQueries -> error:', queryKey[0], error);

              throw error;
            }
          },
          excludedHttpCodes: [],
        },
        query?.options || {}
      );
    }),
  });

  const response = {};

  theQueries.forEach((theQuery) => {
    if (isUndefined(theQuery?.data)) {
      return false;
    }
    const query = { ...theQuery };
    const keys = Object.keys(theQuery?.data);
    query.data = theQuery?.data?.[keys[0]];

    response[keys[0]] = query;
    return true;
  });

  return response;
}

/**
 * clearCache
 *
 * Clears the cache for the provided query keys.
 *
 * @param queryKeys
 * @returns {Promise<Awaited<void>[]>|Promise<void>}
 */
async function clearCache(queryKeys = []) {
  if (isEmpty(queryKeys)) {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await queryClient.invalidateQueries();
  }

  const promises = queryKeys.map(async (queryKey) => {
    // eslint-disable-next-line @typescript-eslint/return-await
    return await queryClient.invalidateQueries({
      predicate: (query) => {
        return query.queryKey?.[0] === queryKey || query.queryKey?.[0].startsWith(queryKey);
      },
    });
  });

  return Promise.all(promises);
}

/**
 * ApiProvider
 *
 * Provides a simple way to make api requests throughout the application without reinventing the wheel.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export const ApiProvider = (props) => {
  const { children } = props;

  const value = useMemo(() => {
    return {
      queries: requests?.queries,
      mutations: requests?.mutations,
      useApiQuery,
      useApiQueries,
      useApiMutation,
      queryClient,
      clearApiCache: clearCache,
    };
  }, []);

  return (
    <ErrorHandler location="src/context/Api/Api.jsx">
      <QueryClientProvider client={queryClient}>
        <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorHandler>
  );
};

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

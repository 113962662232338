import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isNil, isUndefined, omit } from 'lodash';
import { translateCriteriaFilters } from '@src/routes/private/ActionPaths/includes/functions';

/**
 * ListCommonCriteria
 *
 * Retrieves a list of common criteria from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const ListCommonCriteria = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = omit(payload?.[1], ['page', 'size', 'sort']);

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': '/criteria',
      },
      params: { queryKey: theQueryKey },
    };

    if (!isUndefined(payload?.[1]?.page)) {
      requestArgs.params.page = payload?.[1]?.page;
    }

    if (!isUndefined(payload?.[1]?.size)) {
      requestArgs.params.size = payload?.[1]?.size;
    }

    if (!isUndefined(payload?.[1]?.sort)) {
      requestArgs.params.sort = payload?.[1]?.sort;
    }

    if (!isEmpty(thePayload)) {
      requestArgs.headers['x-api-endpoint'] += '/search';
      requestArgs.method = 'POST';
      requestArgs.data = thePayload;
    }

    const remoteResponse = await Axios.request(requestArgs);

    remoteResponse.data.content = remoteResponse.data.content.map((criteria) => {
      const theCriteria = { ...criteria };

      if (!isNil(theCriteria?.activeCommonCriteriaVersion?.criteria)) {
        theCriteria.activeCommonCriteriaVersion.criteria = translateCriteriaFilters(
          theCriteria?.activeCommonCriteriaVersion?.criteria,
          'incoming'
        );
      }

      return theCriteria;
    });

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/ListCommonCriteria.js -> ListCommonCriteria() -> error:', theError);

    throw error;
  }
};

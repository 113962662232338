import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { translateCriteriaFilters } from '@src/routes/private/ActionPaths/includes/functions';
import { isNil } from 'lodash';

/**
 * GetCommonCriteria
 *
 * Retrieves a single action path from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetCommonCriteria = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': `/criteria/${thePayload?.id}`,
      },
      params: { queryKey: theQueryKey },
    };

    const remoteResponse = await Axios.request(requestArgs);

    if (!isNil(remoteResponse?.data?.activeCommonCriteriaVersion?.criteria)) {
      remoteResponse.data.activeCommonCriteriaVersion.criteria = translateCriteriaFilters(
        remoteResponse?.data?.activeCommonCriteriaVersion?.criteria,
        'incoming'
      );
    }

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/GetCommonCriteria.js -> GetCommonCriteria() -> error:', theError);

    throw error;
  }
};

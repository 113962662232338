import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { dayjs } from '@abyss/web/tools/dayjs';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Table as TableComponent } from '@src/components/Table-static';
import configuration from './includes/configuration.json';

/**
 * Table
 *
 * Data containing remediating events.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Table = (props) => {
  const { rows } = props;

  /**
   * renderCellDate
   *
   * display date in MM/DD/YYYY, HH:mm:ss format.
   *
   * @param cell
   * @returns {*}
   */
  const renderCellDate = ({ cell }) => {
    return dayjs(cell?.value).format('MM/DD/YYYY, HH:mm:ss');
  };

  /**
   * renderCellActiveActionPath
   *
   * display active action path in uppercase.
   *
   * @param cell
   * @returns {string}
   */
  const renderCellActiveActionPath = ({ cell }) => {
    return String(cell?.value).toUpperCase();
  };

  /**
   * renderCellRemediationMethods
   *
   * display remediation methods in uppercase.
   *
   * @param cell
   * @returns {string}
   */
  const renderCellRemediationMethods = ({ cell }) => {
    return String(cell?.value.join(', ')).toUpperCase();
  };

  /**
   * Columns for table.
   */
  const columns = useMemo(() => {
    return configuration?.initialColumns.map((item) => {
      const column = item;

      if (column.Header === 'Date') {
        column.Cell = renderCellDate;
      }

      if (column.Header === 'Active Action Path') {
        column.Cell = renderCellActiveActionPath;
      }

      if (column.Header === 'Remediation Methods') {
        column.Cell = renderCellRemediationMethods;
      }

      return column;
    });
  }, []);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/RemediationEvents/components/Table/Table.jsxx">
      <TableComponent
        {...{
          columns,
          rows,
          configuration,
          dataKey: `EidSearch-RemediationEvents-Table`,
        }}
      />
    </ErrorHandler>
  );
};

Table.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string,
      activeActionPath: PropTypes.string,
      remediationMethods: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

Table.defaultProps = {
  rows: [],
};

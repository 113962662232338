import fields from '@src/routes/private/ActionPaths/includes/fields.json';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { SelectInput } from '@abyss/web/ui/SelectInput';

const { exitCriteriaMethod: exitCriteriaMethodField } = fields;

/**
 * Field: ExitCriteriaMethod
 *
 * Select what defines the action path as resolved/completed.
 *
 * @returns {Element}
 * @constructor
 */
export const ExitCriteriaMethod = () => {
  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/Manage/components/Wizard/components/fields/ExitCriteriaMethod/ExitCriteriaMethod.jsx">
      <SelectInput {...exitCriteriaMethodField} css={{ width: 'auto' }} />
    </ErrorHandler>
  );
};

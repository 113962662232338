import PropTypes from 'prop-types';
import React from 'react';
import { Badge } from '@abyss/web/ui/Badge';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';

/**
 * ModeBadge
 *
 * displays the mode of the action path.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const ModeBadge = (props) => {
  const { actionPath, mode } = props;

  if (mode === 'automatic' || actionPath?.manualAssociation === false) {
    return (
      <Badge
        icon={<IconSymbol variant="outlined" style={{ color: 'inherit' }} size={16} icon="autorenew" />}
        variant="success"
        outline
      >
        Automatic
      </Badge>
    );
  }

  if (mode === 'manual' || actionPath?.manualAssociation === true) {
    return (
      <Badge
        icon={<IconSymbol variant="outlined" style={{ color: 'inherit' }} size={16} icon="back_hand" />}
        variant="warning"
        outline
      >
        Manual
      </Badge>
    );
  }

  return null;
};

ModeBadge.propTypes = {
  actionPath: PropTypes.shape({
    manualAssociation: PropTypes.bool,
  }),
  mode: PropTypes.string,
};

ModeBadge.defaultProps = {
  actionPath: {},
  mode: '',
};

import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { FloatingSection } from '@abyss/web/ui/FloatingSection';
import { Navigation } from './components/Navigation';
import { SidebarSkeleton } from './components/Skeleton';

/**
 * Sidebar
 *
 * @TODO Needs description.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Sidebar = (props) => {
  const { isOpen, toggleSidebar, isSearching, setCurrentTarget, currentAccessor, hasDOB, hasSSN } = props;
  const containerRef = useRef(null);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Sidebar/Sidebar.jsx">
      {isSearching ? (
        <SidebarSkeleton />
      ) : (
        <div ref={containerRef} style={{ height: '100%' }}>
          <FloatingSection
            position="top"
            containerRef={containerRef}
            space={64}
            alwaysFloat={false}
            css={{
              'abyss-floating-section-root': {
                boxShadow: 'none',
                '.abyss-drawer-menu-container': {
                  backgroundColor: 'var(--abyss-colors-gray1)',
                },
              },
            }}
          >
            <Navigation
              toggleSidebar={toggleSidebar}
              isSidebarOpen={isOpen}
              setCurrentTarget={setCurrentTarget}
              currentAccessor={currentAccessor}
              hasDOB={hasDOB}
              hasSSN={hasSSN}
            />
          </FloatingSection>
        </div>
      )}
    </ErrorHandler>
  );
};

Sidebar.propTypes = {
  currentAccessor: PropTypes.string,
  hasDOB: PropTypes.bool,
  hasSSN: PropTypes.bool,
  isOpen: PropTypes.bool,
  isSearching: PropTypes.bool,
  setCurrentTarget: PropTypes.func,
  toggleSidebar: PropTypes.func,
};

Sidebar.defaultProps = {
  currentAccessor: '',
  hasDOB: false,
  hasSSN: false,
  isOpen: false,
  isSearching: false,
  setCurrentTarget: () => {},
  toggleSidebar: () => {},
};

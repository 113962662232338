import PropTypes from 'prop-types';
import React from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { useApi } from '@src/context/Api';
import { Table } from './components/Table';

/**
 * TrustedValueHistory
 *
 * Displays list of trusted record changes about the risk record.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const TrustedValueHistory = (props) => {
  const { riskRecord } = props;

  const eid = riskRecord?.eid;

  const { useApiQuery } = useApi();

  const [ListTrustedValueHistory, { data, error, isLoading, isFetching }] = useApiQuery('ListTrustedValueHistory');

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/TrustedValueHistory/TrustedValueHistory.jsx">
      <Grid>
        <Grid.Col span={{ xs: '100%' }}>
          <Table
            error={error}
            isLoading={isLoading || isFetching}
            requestArgs={{ page: 0, size: 25, sort: 'flipDate,desc', eid }}
            requestFunction={ListTrustedValueHistory}
            requestKey="ListTrustedValueHistory"
            rows={data?.content || []}
            totalPages={data?.totalPages || 1}
            totalRecords={data?.totalElements || 0}
          />
        </Grid.Col>
      </Grid>
    </ErrorHandler>
  );
};

TrustedValueHistory.propTypes = {
  riskRecord: PropTypes.shape({
    eid: PropTypes.string,
  }),
};

TrustedValueHistory.defaultProps = {
  riskRecord: null,
};

import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { translateCriteria } from '@src/routes/private/ActionPaths/includes/functions';
import { isNil } from 'lodash';

/**
 * GetRiskRecordsCount
 *
 * Retrieves count of risk records based on entrance criteria.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetRiskRecordsCount = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'POST',
      headers: {
        'x-api-endpoint': `/risk-records/count/entranceAndExit`,
      },
      params: { queryKey: theQueryKey },
      data: {},
    };

    delete thePayload?.criteria?.entrance?.mergedStr;
    delete thePayload?.criteria?.exit?.mergedStr;

    if (!isNil(thePayload?.criteria)) {
      requestArgs.data.criteria = translateCriteria(thePayload?.criteria, 'outgoing');
    }

    const remoteResponse = await Axios.request(requestArgs);

    return remoteResponse?.data;
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/GetRiskRecordsCount.js -> GetRiskRecordsCount() -> error:', theError);

    throw error;
  }
};

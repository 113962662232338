/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useMemo } from 'react';
import { config } from '@abyss/web/tools/config';
import { isEmpty, isNull, merge } from 'lodash';
import { Outlet, useRoutes } from 'react-router-dom';
import { useCurrentUser } from '@src/hooks/useCurrentUser';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { useRoutesContext } from '@src/context/Routes';
import { Actions } from './Admin/screens/Actions';
import { AlertConfig } from './Admin/screens/AlertConfig';
import { ManageActionPath } from './ActionPaths/screens/Manage';
import { EidSearch, RiskAnalysis } from './Analysis';
import { ListActionPaths } from './ActionPaths/screens/List';
import { ListAlerts } from './Notifications/screens/Alerts/List';
import { ListCodeCategories } from './Admin/screens/CodeCategories';
import { ListCodes } from './Admin/screens/Codes';
import { CommonCriteria } from './Admin/screens/CommonCriteria';
import { ListEvents } from './Notifications/screens/Events/List';
import { ListTags } from './Admin/screens/Tags';
import { OperationsDashboard } from './Dashboards/screens/Operations';
import { RiskDashboard } from './Dashboards/screens/Risk';
import { TagHistoryDashboard } from './Dashboards/screens/TagHistory';
import { ViewActionPath } from './ActionPaths/screens/View';
import { ViewEvent } from './Notifications/screens/Events/View';

/**
 * Routes
 *
 * Configures the routes for the application within an authenticated state.
 *
 * @returns {*}
 * @constructor
 */
export const Routes = () => {
  const { roles: userRoles } = useCurrentUser();
  const { currentRoute, setCurrentRoute, currentRoutes, setCurrentRoutes } = useRoutesContext();

  const router = useRouter();
  const location = router?.getLocation();
  const path = location?.pathname;
  const routeParams = router?.getRouteParams();
  const queryString = location?.search;
  const queryParams = Object.fromEntries(new URLSearchParams(queryString));

  /**
   * routerConfiguration
   *
   * Control visibility like this:
   *
   *         visibility: {
   *           disabledEnvironments: ['Stage', 'Production'],
   *           enabledEnvironments: ['Local', 'Development'],
   *         },
   *
   */
  const routerConfiguration = useMemo(() => {
    return [
      {
        element: <Outlet />,
        menuOrder: 0,
        navigationLabel: config('APP_NAME'),
        path: '/',
        screenTitle: config('APP_NAME'),
        showInNavigation: false,
        slug: 'root',
      },
      {
        accessor: 'Dashboards',
        element: <Outlet />,
        hideSubNav: false,
        menuOrder: 1,
        navigationIcon: 'dashboard',
        navigationLabel: 'Dashboards',
        path: '/dashboards',
        screenTitle: 'Dashboards',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'dashboards',
        children: [
          {
            accessor: 'OperationsDashboard',
            element: <OperationsDashboard />,
            menuOrder: 1,
            navigationLabel: 'Operations',
            path: '/dashboards/operations',
            screenTitle: 'Operations Dashboard',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'operations-dashboard',
          },
          {
            accessor: 'RiskDashboard',
            element: <RiskDashboard />,
            menuOrder: 2,
            navigationLabel: 'Risk',
            path: '/dashboards/risk',
            screenTitle: 'Risk Dashboard',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'risk-dashboard',
          },
          {
            accessor: 'TagHistoryDashboard',
            element: <TagHistoryDashboard />,
            menuOrder: 3,
            navigationLabel: 'Tag History',
            path: '/dashboards/tag-history',
            screenTitle: 'Tag History Dashboard',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'tag-history-dashboard',
          },
        ],
      },
      {
        accessor: 'Analysis',
        element: <Outlet />,
        hideSubNav: false,
        menuOrder: 2,
        navigationIcon: 'bar_chart',
        navigationLabel: 'Analysis',
        path: '/analysis',
        screenTitle: 'Analysis',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'analysis',
        children: [
          {
            accessor: 'EidSearch',
            element: <EidSearch />,
            menuOrder: 1,
            navigationLabel: 'EID Search',
            path: '/analysis/eid-search',
            screenTitle: 'EID Search',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'eid-search',
            children: [
              {
                accessor: 'EidSearch',
                element: <EidSearch />,
                menuOrder: 0,
                navigationLabel: 'EID Search',
                path: '/analysis/eid-search/:eid',
                screenTitle: 'EID Search',
                showInBreadcrumbs: false,
                showInNavigation: false,
                slug: 'eid-search',
              },
            ],
          },
          {
            accessor: 'RiskAnalysis',
            element: <RiskAnalysis />,
            menuOrder: 2,
            navigationLabel: 'Risk Analysis',
            path: '/analysis/risk-analysis',
            screenTitle: 'Risk Analysis',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'risk-analysis',
            children: [
              {
                accessor: 'RiskAnalysis',
                element: <RiskAnalysis />,
                menuOrder: 0,
                navigationLabel: 'Risk Analysis',
                path: '/analysis/risk-analysis/:key',
                screenTitle: 'Risk Analysis',
                showInBreadcrumbs: false,
                showInNavigation: false,
                slug: 'risk-analysis',
              },
            ],
          },
        ],
      },
      {
        accessor: 'ListActionPaths',
        element: <Outlet />,
        hideSubNav: true,
        menuOrder: 3,
        navigationIcon: 'create_new_folder',
        navigationLabel: 'Action Paths',
        path: '/action-paths',
        screenTitle: 'Action Paths',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'list-action-paths',
        children: [
          {
            accessor: 'ListActionPaths',
            element: <ListActionPaths />,
            index: true,
            menuOrder: 0,
            navigationLabel: 'Action Paths',
            path: '/action-paths',
            screenTitle: 'Action Paths',
            showInBreadcrumbs: true,
            showInNavigation: false,
            slug: 'list-action-paths',
          },
          {
            accessor: 'ViewActionPath',
            element: <ViewActionPath />,
            menuOrder: 0,
            navigationLabel: 'View Action Path',
            path: '/action-paths/:id',
            screenTitle: 'View Action Path',
            showInBreadcrumbs: true,
            showInNavigation: false,
            slug: 'view-action-path',
          },
          {
            accessor: 'ManageActionPath',
            element: <ManageActionPath />,
            exact: true,
            menuOrder: 0,
            navigationLabel: 'Manage',
            path: '/action-paths/:id/:action/:mode/:status/step/:stepNumber',
            screenTitle: 'Manage',
            showInBreadcrumbs: true,
            showInNavigation: false,
            slug: 'manage-action-path',
          },
          {
            accessor: 'ManageActionPath',
            element: <ManageActionPath />,
            exact: true,
            menuOrder: 0,
            navigationLabel: 'Manage',
            path: '/action-paths/:id/:action/:mode/:status/step/:stepNumber/:key',
            screenTitle: 'Manage',
            showInBreadcrumbs: true,
            showInNavigation: false,
            slug: 'manage-action-path',
          },
        ],
      },
      {
        accessor: 'Notifications',
        element: <Outlet />,
        hideSubNav: false,
        menuOrder: 4,
        navigationIcon: 'notifications',
        navigationLabel: 'Notifications',
        path: '/notifications',
        screenTitle: 'Notifications',
        showInBreadcrumbs: true,
        showInNavigation: true,
        slug: 'notifications',
        children: [
          {
            accessor: 'ListAlerts',
            element: <ListAlerts />,
            menuOrder: 1,
            navigationIcon: 'notifications',
            navigationLabel: 'Alerts',
            path: '/notifications/alerts',
            screenTitle: 'Alerts',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'notifications-list-alerts',
          },
          {
            accessor: 'ListEvents',
            element: <Outlet />,
            menuOrder: 2,
            navigationIcon: 'notifications',
            navigationLabel: 'Events',
            path: '/notifications/events',
            screenTitle: 'Events',
            showInBreadcrumbs: true,
            showInNavigation: true,
            slug: 'notifications-list-events',
            children: [
              {
                accessor: 'ListEvents',
                element: <ListEvents />,
                menuOrder: 0,
                navigationIcon: 'notifications',
                navigationLabel: 'Events',
                path: '/notifications/events',
                screenTitle: 'Events',
                showInBreadcrumbs: true,
                showInNavigation: false,
                slug: 'notifications-list-events',
              },
              {
                accessor: 'ViewEvent',
                element: <ViewEvent />,
                menuOrder: 0,
                navigationLabel: 'View Event',
                path: '/notifications/events/:eventId',
                screenTitle: 'View Event',
                showInBreadcrumbs: true,
                showInNavigation: false,
                slug: 'notifications-view-event',
              },
            ],
          },
        ],
      },
      {
        accessor: 'Admin',
        element: <Outlet />,
        hideSubNav: false,
        menuOrder: 5,
        navigationIcon: 'admin_panel_settings',
        navigationLabel: 'Admin',
        path: '/admin',
        screenTitle: 'Admin',
        showInBreadcrumbs: userRoles.includes('State.Admin'),
        showInNavigation: userRoles.includes('State.Admin'),
        slug: 'admin',
        visibility: {
          enabledUserRoles: ['State.Admin'],
          disabledUserRoles: ['State.User'],
        },
        children: [
          {
            accessor: 'AdminActions',
            element: <Actions />,
            menuOrder: 1,
            navigationLabel: 'Actions',
            path: '/admin/actions',
            screenTitle: 'Actions',
            showInBreadcrumbs: userRoles.includes('State.Admin'),
            showInNavigation: userRoles.includes('State.Admin'),
            slug: 'admin-actions',
            visibility: {
              enabledUserRoles: ['State.Admin'],
              disabledUserRoles: ['State.User'],
            },
          },
          {
            accessor: 'AdminAlertConfig',
            element: <AlertConfig />,
            menuOrder: 2,
            navigationLabel: 'Alert Config',
            path: '/admin/alert-config',
            screenTitle: 'Alert Config',
            showInBreadcrumbs: userRoles.includes('State.Admin'),
            showInNavigation: userRoles.includes('State.Admin'),
            slug: 'admin-alert-config',
            visibility: {
              enabledUserRoles: ['State.Admin'],
              disabledUserRoles: ['State.User'],
            },
          },
          {
            accessor: 'AdminCodes',
            element: <ListCodes />,
            menuOrder: 3,
            navigationLabel: 'Codes',
            path: '/admin/codes',
            screenTitle: 'Codes',
            showInBreadcrumbs: userRoles.includes('State.Admin'),
            showInNavigation: userRoles.includes('State.Admin'),
            slug: 'admin-codes',
            visibility: {
              enabledUserRoles: ['State.Admin'],
              disabledUserRoles: ['State.User'],
            },
          },
          {
            accessor: 'AdminCodeCategories',
            element: <ListCodeCategories />,
            menuOrder: 4,
            navigationLabel: 'Code Categories',
            path: '/admin/code-categories',
            screenTitle: 'Code Categories',
            showInBreadcrumbs: userRoles.includes('State.Admin'),
            showInNavigation: userRoles.includes('State.Admin'),
            slug: 'admin-code-categories',
            visibility: {
              enabledUserRoles: ['State.Admin'],
              disabledUserRoles: ['State.User'],
            },
          },
          {
            accessor: 'AdminCommonCriteria',
            element: <CommonCriteria />,
            menuOrder: 5,
            navigationLabel: 'Common Criteria',
            path: '/admin/common-criteria',
            screenTitle: 'Common Criteria',
            showInBreadcrumbs: userRoles.includes('State.Admin'),
            showInNavigation: userRoles.includes('State.Admin'),
            slug: 'admin-common-criteria',
            visibility: {
              enabledUserRoles: ['State.Admin'],
              disabledUserRoles: ['State.User'],
            },
          },
          {
            accessor: 'AdminTags',
            element: <ListTags />,
            menuOrder: 5,
            navigationLabel: 'Tags',
            path: '/admin/tags',
            screenTitle: 'Tags',
            showInBreadcrumbs: userRoles.includes('State.Admin'),
            showInNavigation: userRoles.includes('State.Admin'),
            slug: 'admin-tags',
            visibility: {
              enabledUserRoles: ['State.Admin'],
              disabledUserRoles: ['State.User'],
            },
          },
        ],
      },
    ];
  }, [userRoles]);

  /**
   * Handle Redirects
   */
  useEffect(() => {
    (() => {
      if (path === '/' || path === '/dashboards') {
        return router.navigate('/dashboards/risk');
      }

      if (path === '/analysis') {
        return router.navigate('/analysis/risk-analysis');
      }

      if (path === '/notifications') {
        return router.navigate('/notifications/alerts');
      }

      if (path.includes('/admin') && !userRoles.includes('State.Admin')) {
        return router.navigate('/');
      }

      if (path === '/admin' && userRoles.includes('State.Admin')) {
        return router.navigate('/admin/actions');
      }
      return false;
    })();
  }, [path]);

  /**
   * getTertiaryRoutes
   *
   * @param routes
   * @param secondaryRoute
   * @param secondaryIndex
   * @param primaryIndex
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getTertiaryRoutes = (routes = [], secondaryRoute = {}, secondaryIndex = 0, primaryIndex = 0) => {
    const theRoutes = routes;
    let theRoute = {};

    secondaryRoute?.children.forEach((route, index) => {
      const match = router.matchPath(
        {
          path: route.path,
          exact: false,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[primaryIndex].current = false;
        theRoutes[primaryIndex].inUrl = true;
        theRoutes[primaryIndex].children[secondaryIndex].current = false;
        theRoutes[primaryIndex].children[secondaryIndex].inUrl = true;
        theRoutes[primaryIndex].children[secondaryIndex].children[index].current = true;
        theRoutes[primaryIndex].children[secondaryIndex].children[index].inUrl = true;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              routeParams,
              queryString,
              queryParams,
              parent: secondaryRoute,
            },
          };
        }
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getSecondaryRoutes
   *
   * @param routes
   * @param primaryRoute
   * @param primaryIndex
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getSecondaryRoutes = (routes = [], primaryRoute = {}, primaryIndex = 0) => {
    let theRoutes = routes;
    let theRoute = {};

    primaryRoute?.children.forEach((route, index) => {
      const match = router.matchPath(
        {
          path: route.path,
          exact: true,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[primaryIndex].current = false;
        theRoutes[primaryIndex].inUrl = true;
        theRoutes[primaryIndex].children[index].current = true;
        theRoutes[primaryIndex].children[index].inUrl = true;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              routeParams,
              queryString,
              queryParams,
              parent: primaryRoute,
            },
          };
        }
      } else if (!isEmpty(route?.children)) {
        const tertiaryRoutes = getTertiaryRoutes(theRoutes, route, index, primaryIndex);
        theRoutes = merge([], theRoutes, tertiaryRoutes.theRoutes);
        theRoute = merge({}, theRoute, tertiaryRoutes.theRoute);
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getPrimaryRoutes
   *
   * @param routes
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getPrimaryRoutes = (routes = []) => {
    let theRoutes = routes;
    let theRoute = {};

    routes.forEach((route, index) => {
      const match = router.matchPath(
        {
          path: route.path,
          exact: true,
          strict: false,
        },
        path
      );

      if (!isNull(match)) {
        theRoutes[index].current = true;
        theRoutes[index].inUrl = true;

        if (isEmpty(theRoute)) {
          theRoute = {
            ...route,
            ...match,
            ...{
              routeParams,
              queryString,
              queryParams,
              parent: null,
            },
          };
        }
      } else if (!isEmpty(route?.children)) {
        const secondaryRoutes = getSecondaryRoutes(theRoutes, route, index);
        theRoutes = merge([], theRoutes, secondaryRoutes.theRoutes);
        theRoute = merge({}, theRoute, secondaryRoutes.theRoute);
      }
    });

    return { theRoute, theRoutes };
  };

  /**
   * getRoutes
   *
   * @returns {{theRoute: {}, theRoutes: *[]}}
   */
  const getRoutes = () => {
    return getPrimaryRoutes(routerConfiguration);
  };

  /**
   * Determines the current route(s) based on the URL.
   */
  useEffect(() => {
    const { theRoute, theRoutes } = getRoutes();

    if (theRoute !== currentRoute) {
      setCurrentRoute(theRoute);
    }

    if (theRoutes !== currentRoutes) {
      setCurrentRoutes(theRoutes);
    }
  }, [path, routerConfiguration]);

  return useRoutes(routerConfiguration);
};

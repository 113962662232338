import { Axios } from '@src/context/Api/includes/Axios';
import { config } from '@abyss/web/tools/config';
import { orderBy } from 'lodash';

/**
 * GetRiskRecordTimeline
 *
 * Retrieves a single risk record from the remote API via an authenticated request.
 *
 * @param payload
 * @returns {Promise<any>}
 * @constructor
 */
export const GetRiskRecordTimeline = async (payload = {}) => {
  try {
    const theQueryKey = payload?.[0];
    const thePayload = payload?.[1];

    const requestArgs = {
      baseURL: config('API_URL'),
      method: 'GET',
      headers: {
        'x-api-endpoint': `/risk-records/${thePayload?.eid}/timeline`,
      },
      params: { queryKey: theQueryKey },
    };

    const remoteResponse = await Axios.request(requestArgs);
    const sortedTimelines = orderBy(Object.entries(remoteResponse?.data?.timelines), [0], ['desc']);

    return { timelines: Object.fromEntries(sortedTimelines) };
  } catch (error) {
    let theError = error;

    if (!String(config('APP_ENV')).toLowerCase().includes('local')) {
      theError = JSON.stringify(error);
    }

    console.error('src/requests/queries/GetRiskRecordTimeline.js -> GetRiskRecordTimeline() -> error:', theError);

    throw error;
  }
};

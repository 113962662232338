import { ErrorHandler } from '@src/components/ErrorHandler';
import { motion } from 'framer-motion';
import React from 'react';
import { Grid } from '@abyss/web/ui/Grid';
import { Layout } from '@abyss/web/ui/Layout';
import { AbyssTheme as themeConfiguration } from '@src/client';
import { Text } from '@abyss/web/ui/Text';
import { Badge } from '@abyss/web/ui/Badge';
import { Button } from '@src/components/Button';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Flex } from '@abyss/web/ui/Flex';
import { AggregationView } from '@src/common/widgets/AggregationView';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { useRouter } from '@abyss/web/hooks/useRouter';
import { Styles } from './includes/styles';

/**
 * SearchResults
 *
 * Displays the search results for the Risk Analysis screen.
 *
 * @param props
 * @returns {React.JSX.Element|null}
 * @constructor
 */
export const SearchResults = (props) => {
  const { assets, results, count, searchFilters } = props;

  const router = useRouter();

  if (isEmpty(results)) {
    return null;
  }

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/RiskAnalysis/components/SearchResults/SearchResults.jsx">
      <motion.div
        animate="open"
        variants={{
          open: { opacity: 1 },
          closed: { opacity: 0 },
        }}
        initial={{ opacity: 0 }}
      >
        <Styles>
          <Grid css={{ margin: 0, padding: 0, width: '100%' }}>
            <Grid.Col
              span={{ xs: '100%' }}
              css={{ paddingTop: 'var(--abyss-space-lg)', paddingBottom: 'var(--abyss-space-lg)' }}
            >
              <Layout.Group space={themeConfiguration?.theme?.space?.lg}>
                <Layout.Group>
                  <Text fontWeight="bold">Total Records:</Text>
                  <Badge variant="info" outline>
                    {Number(count || 0).toLocaleString('en-US')}
                  </Badge>
                </Layout.Group>
                <div>
                  <Button
                    variant="solid"
                    before={<IconSymbol icon="edit" variant="outlined" />}
                    onClick={async (event) => {
                      event?.preventDefault();
                      const encodedSearchFilters = Buffer.from(JSON.stringify(searchFilters)).toString('base64');
                      router?.navigate(`/action-paths/0/create/automatic/draft/step/1/${encodedSearchFilters}`);
                    }}
                  >
                    Draft Action Path
                  </Button>
                </div>
              </Layout.Group>
            </Grid.Col>
            <Grid.Col span={{ xs: '100%', sm: '100%', md: '100%', lg: '100%' }} css={{ margin: 0, padding: 0 }}>
              <Flex
                justify="flex-start"
                alignItems="stretch"
                alignContent="flex-start"
                direction="row"
                css={{ width: '100%' }}
              >
                <React.Fragment>
                  {results.map((aggregation) => {
                    const view = assets?.ListViews?.data?.find((theView) => {
                      return theView?.name === aggregation?.researchAggregation?.name;
                    });

                    return (
                      <AggregationView
                        aggregation={aggregation}
                        assets={assets}
                        filters={searchFilters?.filters}
                        key={aggregation?.researchAggregation?.id}
                        view={view}
                      />
                    );
                  })}
                </React.Fragment>
                {searchFilters?.views?.map((viewId) => {
                  if (viewId === 'risk-records') {
                    return (
                      <AggregationView
                        assets={assets}
                        filters={searchFilters?.filters}
                        criteria={searchFilters?.criteria}
                        key="ListRiskRecords"
                        view={{
                          id: 'risk-records',
                          name: 'Risk Records Sample Data',
                          description: 'Sample risk records that match filter criteria.',
                        }}
                      />
                    );
                  }
                  return null;
                })}
              </Flex>
            </Grid.Col>
          </Grid>
        </Styles>
      </motion.div>
    </ErrorHandler>
  );
};

SearchResults.propTypes = {
  assets: PropTypes.shape({
    ListRiskCodes: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
    ListViews: PropTypes.shape({
      data: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          name: PropTypes.string,
        })
      ),
    }),
  }),
  results: PropTypes.arrayOf(
    PropTypes.shape({
      researchAggregation: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
  count: PropTypes.number,
  searchFilters: PropTypes.shape({
    criteria: PropTypes.shape({
      entrance: PropTypes.shape({
        additional: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
          })
        ),
        commonIds: PropTypes.arrayOf(PropTypes.string),
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
      }),
      exit: PropTypes.shape({
        additional: PropTypes.arrayOf(
          PropTypes.shape({
            column: PropTypes.string,
            condition: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
          })
        ),
        commonIds: PropTypes.arrayOf(PropTypes.string),
        commonCriteriaVersionsIds: PropTypes.arrayOf(PropTypes.string),
      }),
    }),
    filters: PropTypes.shape({
      column: PropTypes.string,
      condition: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
    }),
    views: PropTypes.arrayOf(PropTypes.string),
  }),
};

SearchResults.defaultProps = {
  assets: {},
  results: [],
  count: 0,
  searchFilters: {},
};

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ActionNav } from '@abyss/web/ui/ActionNav';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Flex } from '@abyss/web/ui/Flex';
import { Grid } from '@abyss/web/ui/Grid';
import { Heading } from '@abyss/web/ui/Heading';
import { Indicator } from '@abyss/web/ui/Indicator';
import { isEmpty } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { ModeBadge } from '@src/routes/private/ActionPaths/components/ModeBadge';
import { isValidStep } from '../../../includes/functions';
import { NameField } from '../../fields/Name';
import { Styles } from './includes/styles';
import { useWizardContext } from '../../../context';

/**
 * Header
 *
 * Displays the total number of steps, the current step, and allows the user to input the action path name.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Header = (props) => {
  const { form } = props;

  const wizardContext = useWizardContext();

  const { actionPath, steps, previousStep, isManual, currentStep } = wizardContext;

  const validName = !!isEmpty(form?.formState?.errors?.name);
  const isCurrentStepValid = isValidStep(currentStep, validName, form?.formState?.errors, isManual);

  /**
   * Highlights each current/completed step.
   */
  useEffect(() => {
    const navigationItems = document.querySelectorAll('.abyss-action-nav-list-item');

    if (navigationItems instanceof NodeList && !isEmpty(navigationItems)) {
      navigationItems.forEach((navigationItem, index) => {
        const classes = navigationItem.classList;
        classes.remove('current', 'completed');

        if (currentStep?.order - 1 === index && !classes.contains('current')) {
          classes.add('current');
        }

        if (currentStep?.order - 1 > index && !classes.contains('completed')) {
          classes.add('completed');
        }
      });
    }
  }, [currentStep, steps, isCurrentStepValid]);

  return (
    <ErrorHandler location="src/routes/private/ActionPaths/screens/Manage/components/Wizard/components/Layout/Header/Header.jsx">
      <Styles>
        <Grid>
          <Grid.Col>
            <Heading offset={0}>
              <Layout.Group>
                <div>
                  <span className="capitalize">{wizardContext?.action}</span> Action Path
                </div>
                <div>
                  <ModeBadge mode={wizardContext?.mode} />
                </div>
              </Layout.Group>
            </Heading>
          </Grid.Col>
          <Grid.Col
            span={{
              xs: '100%',
            }}
          >
            <ActionNav title="Action Path" subText={<NameField form={form} actionPath={actionPath} />}>
              {steps?.map((step, index) => {
                return (
                  <ActionNav.Item
                    key={`${steps[index]?.key}`}
                    href=""
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                    }}
                    title=""
                    hideIcon
                    actionText={
                      <Flex justify="right" alignItems="center" direction="row">
                        <Indicator
                          withBorder
                          label={index + 1}
                          offset={-1}
                          size="small"
                          showZero={false}
                          position="top-start"
                        />
                        <div>{step?.label}</div>
                      </Flex>
                    }
                    variant={currentStep?.order > previousStep?.order ? 'success' : 'info'}
                  />
                );
              })}
            </ActionNav>
          </Grid.Col>
        </Grid>
      </Styles>
    </ErrorHandler>
  );
};

Header.propTypes = {
  form: PropTypes.shape({
    formState: PropTypes.shape({
      errors: PropTypes.shape({
        name: PropTypes.shape({
          message: PropTypes.string,
        }),
      }),
    }),
  }),
};

Header.defaultProps = {
  form: {},
};

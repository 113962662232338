import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Accordion } from '@abyss/web/ui/Accordion';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Indicator } from '@abyss/web/ui/Indicator';
import { isUndefined, orderBy } from 'lodash';
import { Layout } from '@abyss/web/ui/Layout';
import { Table } from './components/Table';

/**
 * Attribute
 *
 * Displays reported information regarding the risk record's affiliated DOB or SSN.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Attribute = (props) => {
  const { riskRecord, options } = props;

  let attributeType = null;

  if (options?.accessor.includes('ssn')) {
    attributeType = 'ssn';
  }

  if (options?.accessor.includes('dob')) {
    attributeType = 'dob';
  }

  const ireRiskRecord = riskRecord?.ireRiskRecord;

  const [trustedValue, setTrustedValue] = useState('');
  const [attributeData, setAttributeData] = useState({});

  /**
   * store the attribute values and their respective sources and corresponding record IDs.
   */
  useEffect(() => {
    if (!isUndefined(ireRiskRecord?.remediationFindings)) {
      /**
       * find the attribute
       */
      const theAttribute = ireRiskRecord?.remediationFindings.find((attribute) => {
        return attribute.attributeType === attributeType;
      });

      const theTrustedValue = theAttribute?.trustedValue;

      const values = {
        [theTrustedValue]: [],
      };

      /**
       * store trusted sources and their respective record IDs with the trusted value
       */
      ireRiskRecord?.trustedRecordSources?.forEach((record) => {
        const trustedRecord = {
          source: record?.source,
          id: record?.recordId,
        };

        if (!values[theTrustedValue].includes(trustedRecord)) {
          values[theTrustedValue].push(trustedRecord);
        }
      });

      /**
       * store untrusted values
       */
      theAttribute?.untrustedRecordSources?.forEach((record) => {
        if (isUndefined(values[record?.value])) {
          values[record?.value] = [];
        }
      });

      /**
       * store untrusted sources and their respective record IDs with the untrusted values
       */
      theAttribute?.untrustedRecordSources?.forEach((record) => {
        const untrustedRecord = {
          source: record?.source,
          id: record?.recordId,
        };

        if (!values[record?.value].includes(untrustedRecord)) {
          values[record?.value].push(untrustedRecord);
        }
      });

      if (theTrustedValue !== trustedValue) {
        setTrustedValue(theTrustedValue);
      }

      if (values !== attributeData) {
        setAttributeData(values);
      }
    }
  }, [ireRiskRecord]);

  /**
   * accordionItems
   *
   * prepare the accordion items for the attribute.
   *
   * @type {*[]}
   */
  const accordionItems = useMemo(() => {
    return orderBy(
      Object.keys(attributeData).map((attributeValue) => {
        return {
          value: attributeValue,
          sources: attributeData[attributeValue],
          sourceCount: Object.keys(attributeData[attributeValue])?.length,
          type: String(attributeValue) === String(trustedValue) ? 'trusted' : 'untrusted',
        };
      }),
      ['type'],
      ['asc']
    );
  }, [attributeData]);

  return (
    <ErrorHandler location="src/routes/private/Analysis/screens/EidSearch/components/Layout/Main/components/Results/widgets/Attribute/Attribute.jsx">
      <Accordion type="multiple" isCollapsible>
        {accordionItems?.map((item) => {
          const { value, sources, sourceCount, type } = item;

          return (
            <Accordion.Item key={`${item?.value}`} value={item?.value}>
              <Accordion.Trigger>
                <Accordion.Header>
                  <Layout.Group>
                    <div>
                      {type === 'trusted' ? (
                        <IconSymbol icon="check_circle" variant="outlined" color="var(--abyss-colors-success1)" />
                      ) : (
                        <IconSymbol icon="error" variant="outlined" color="var(--abyss-colors-error1)" />
                      )}
                    </div>
                    <div>
                      {sourceCount > 1 ? (
                        <div style={{ width: 'auto' }}>
                          <Indicator label={sourceCount} showZero={false} withBorder color="var(--abyss-colors-info1)">
                            <div style={{ marginRight: 'var(--abyss-space-sm)' }}> {value}</div>
                          </Indicator>
                        </div>
                      ) : (
                        <React.Fragment>{value}</React.Fragment>
                      )}
                    </div>
                  </Layout.Group>
                </Accordion.Header>
              </Accordion.Trigger>
              <Accordion.Content>
                <Table rows={sources} type={type} />
              </Accordion.Content>
            </Accordion.Item>
          );
        })}
      </Accordion>
    </ErrorHandler>
  );
};

Attribute.propTypes = {
  riskRecord: PropTypes.shape({
    ireRiskRecord: PropTypes.shape({
      remediationFindings: PropTypes.arrayOf(
        PropTypes.shape({
          attributeType: PropTypes.string,
          trustedValue: PropTypes.string,
          untrustedRecordSources: PropTypes.arrayOf(
            PropTypes.shape({
              source: PropTypes.string,
              recordId: PropTypes.string,
            })
          ),
        })
      ),
      trustedRecordSources: PropTypes.arrayOf(
        PropTypes.shape({
          source: PropTypes.string,
          recordId: PropTypes.string,
        })
      ),
    }),
  }),
  options: PropTypes.shape({
    accessor: PropTypes.string,
  }),
};

Attribute.defaultProps = {
  riskRecord: {},
  options: {},
};

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { Grid } from '@abyss/web/ui/Grid';
import { isEmpty, isUndefined } from 'lodash';
import { Loader } from '@src/components/Loader';
import { useApi } from '@src/context/Api';
import { Widget } from '@src/components/Widget';
import { Alert } from '@abyss/web/ui/Alert';
import { Styles } from './includes/styles';
import { Table } from './components/Table';

/**
 * Widget: CommonCriteria
 *
 * Displays a list of common criteria filters to apply to an api request.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const CommonCriteria = (props) => {
  const {
    context,
    fetchCriteria,
    fetchCriteriaVersions,
    filterKey,
    form,
    isCollapsible,
    needsRemoval,
    needsReview,
    needsUpdate,
    showActions,
    setSelected,
  } = props;

  const { useApiQuery } = useApi();

  const [ListCommonCriteria, { data, isLoading, isFetching }] = useApiQuery('ListCommonCriteria');

  /**
   * Fetch common criteria from the API.
   */
  useEffect(() => {
    if (isUndefined(data)) {
      ListCommonCriteria({
        isActive: true,
        page: 0,
        size: 9999,
        sort: 'name,asc',
      });
    }
  }, [data]);

  return (
    <ErrorHandler location="src/common/widgets/CommonCriteria/CommonCriteria.jsx">
      <Styles>
        <Widget
          backgroundColor="var(--abyss-colors-gray1)"
          collapsible={isCollapsible}
          icon="settings"
          title="Common Criteria"
        >
          <Grid>
            {isUndefined(data) || isLoading || isFetching ? (
              <Grid.Col
                span={{
                  xs: '100%',
                }}
              >
                <Loader backgroundColor="transparent" height="100%" width="100%" />
              </Grid.Col>
            ) : (
              [
                <React.Fragment>
                  {(!isEmpty(needsReview?.update) || !isEmpty(needsReview?.remove)) && (
                    <React.Fragment>
                      {!isEmpty(needsReview?.remove) && (
                        <Grid.Col span={{ xs: '100%' }}>
                          <Alert title="Deprecated Criteria" variant="warning">
                            {needsReview?.remove.join(', ')} {needsReview?.remove.length > 1 ? 'are' : 'is'} no longer
                            supported. Please click the remove button to advance the{' '}
                            {needsReview?.remove.length > 1 ? 'action paths' : 'action path'}.
                          </Alert>
                        </Grid.Col>
                      )}
                      {!isEmpty(needsReview?.update) && (
                        <Grid.Col span={{ xs: '100%' }}>
                          <Alert title="Updated Criteria" variant="warning">
                            {needsReview?.update.join(', ')} {needsReview?.update.length > 1 ? 'have' : 'has'} been
                            updated. Please click the update button to advance the{' '}
                            {needsReview?.update.length > 1 ? 'action paths' : 'action path'}.
                          </Alert>
                        </Grid.Col>
                      )}
                    </React.Fragment>
                  )}
                </React.Fragment>,

                <Grid.Col
                  span={{
                    xs: '100%',
                  }}
                >
                  <Table
                    context={context}
                    fetchCriteria={fetchCriteria}
                    fetchCriteriaVersions={fetchCriteriaVersions}
                    filterKey={filterKey}
                    form={form}
                    needsRemoval={needsRemoval}
                    needsUpdate={needsUpdate}
                    rows={data?.content || []}
                    showActions={showActions}
                    setSelected={setSelected}
                  />
                </Grid.Col>,
              ]
            )}
          </Grid>
        </Widget>
      </Styles>
    </ErrorHandler>
  );
};

CommonCriteria.propTypes = {
  context: PropTypes.string,
  fetchCriteria: PropTypes.func,
  fetchCriteriaVersions: PropTypes.func,
  filterKey: PropTypes.string,
  form: PropTypes.shape({}),
  isCollapsible: PropTypes.bool,
  needsRemoval: PropTypes.shape({
    criteria: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      note: PropTypes.string,
      isActive: PropTypes.bool,
      activeVersionNbr: PropTypes.number,
      activeCommonCriteriaVersion: PropTypes.string,
      createdDate: PropTypes.string,
      createdBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      context: PropTypes.string,
    }),
    version: PropTypes.shape({
      id: PropTypes.string,
      commonCriteriaId: PropTypes.string,
      version: PropTypes.number,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      createdDate: PropTypes.string,
      createdBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      context: PropTypes.string,
    }),
  }),
  needsReview: PropTypes.shape({
    remove: PropTypes.arrayOf(PropTypes.string),
    update: PropTypes.arrayOf(PropTypes.string),
  }),
  needsUpdate: PropTypes.shape({
    from: PropTypes.shape({
      id: PropTypes.string,
      commonCriteriaId: PropTypes.string,
      version: PropTypes.number,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      createdDate: PropTypes.string,
      createdBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      lastModifiedBy: PropTypes.string,
      context: PropTypes.string,
    }),
    to: PropTypes.shape({
      id: PropTypes.string,
      commonCriteriaId: PropTypes.string,
      version: PropTypes.number,
      criteria: PropTypes.arrayOf(
        PropTypes.shape({
          column: PropTypes.string,
          condition: PropTypes.string,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.arrayOf(PropTypes.string)]),
        })
      ),
      createdDate: PropTypes.string,
      createdBy: PropTypes.string,
      lastModifiedDate: PropTypes.string,
      lastModifiedBy: PropTypes.string,
    }),
  }),
  showActions: PropTypes.bool,
  setSelected: PropTypes.func,
};

CommonCriteria.defaultProps = {
  context: 'entrance',
  fetchCriteria: () => {},
  fetchCriteriaVersions: () => {},
  filterKey: 'criteria.entrance',
  form: {},
  isCollapsible: false,
  needsRemoval: {},
  needsReview: {},
  needsUpdate: {},
  showActions: false,
  setSelected: () => {},
};

import { isEmpty, isUndefined, uniq } from 'lodash';
import { useApi } from '@src/context/Api';
import { useCallback, useEffect, useMemo, useState } from 'react';

/**
 * useCommonCriteria
 *
 * Makes API requests to retrieve the assets required for the wizard experience.
 *
 * @returns {{isLoading: boolean, data: *}}
 */
export const useCommonCriteria = () => {
  const [isLoading, setIsLoading] = useState(false);

  const { useApiQueries, queryClient } = useApi();

  const [theCriteria, setTheCriteria] = useState({});
  const [theQueries, setTheQueries] = useState([]);

  const queries = useApiQueries(theQueries);

  /**
   * Determines the overall loading state of all asset queries.
   */
  useEffect(() => {
    if (
      !isEmpty(queries) &&
      Object.keys(queries).length === theQueries.length &&
      isEmpty(
        Object.keys(queries).filter((assetKey) => {
          const query = queries[assetKey];
          return !(!query?.isLoading && !query?.isFetching);
        })
      )
    ) {
      setIsLoading(false);
    } else {
      setIsLoading(true);
    }
  }, [queries, theQueries]);

  const fetchData = useCallback((criteria = {}) => {
    let ids = [];

    // Entrance Criteria
    if (!isUndefined(criteria?.entrance?.commonIds) && !isEmpty(criteria?.entrance?.commonIds)) {
      ids = [...ids, ...criteria?.entrance?.commonIds];
    }

    // Exit Criteria
    if (!isUndefined(criteria?.exit?.commonIds) && !isEmpty(criteria?.exit?.commonIds)) {
      ids = [...ids, ...criteria?.exit?.commonIds];
    }

    ids = uniq(ids);

    if (!isEmpty(ids)) {
      const requests = [];

      ids.forEach((id) => {
        requests.push({
          key: `GetCommonCriteria-${id}`,
          args: { id },
        });
      });

      if (requests !== theQueries) {
        setTheQueries(requests);
      }

      if (criteria !== theCriteria) {
        setTheCriteria(criteria);
      }
    } else {
      setTheCriteria({});
      setTheQueries([]);
      queryClient.removeQueries({
        predicate: (query) => {
          return query.queryKey?.[0].startsWith('GetCommonCriteria');
        },
      });
    }
  }, []);

  const namesList = useMemo(() => {
    const theCommonCriteria = [];
    if (!isEmpty(queries)) {
      Object.keys(queries).forEach((queryKey) => {
        theCommonCriteria.push(queries?.[queryKey]?.data?.name);
      });
    }
    return theCommonCriteria;
  }, [queries]);

  const criteriaList = useMemo(() => {
    const theCommonCriteria = [];
    if (!isEmpty(queries)) {
      Object.keys(queries).forEach((queryKey) => {
        const context = [];

        const entrance = theCriteria?.entrance?.commonIds?.find((id) => {
          return queries?.[queryKey]?.data?.id === id;
        });

        if (!isUndefined(entrance)) {
          context.push('entrance');
        }

        const exit = theCriteria?.exit?.commonIds?.find((id) => {
          return queries?.[queryKey]?.data?.id === id;
        });

        if (!isUndefined(exit)) {
          context.push('exit');
        }

        theCommonCriteria.push({ ...queries?.[queryKey]?.data, ...{ context } });
      });
    }
    return theCommonCriteria;
  }, [queries]);

  return useMemo(() => {
    return { isLoading, data: queries, fetch: fetchData, namesList, criteriaList };
  }, [isLoading, queries, namesList, criteriaList]);
};

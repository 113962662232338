import { useApi } from '@src/context/Api';
import { useMemo } from 'react';

/**
 * useSearch
 *
 * Makes API requests to retrieve the assets required for the wizard experience.
 *
 * @returns {{isLoading, data: *, fetch: *}}
 */
export const useSearch = () => {
  const { useApiQuery } = useApi();

  const [GetRiskRecord, { data: searchResults, isLoading, isFetching }] = useApiQuery('GetRiskRecord', {
    excludedHttpCodes: [404],
  });

  return useMemo(() => {
    return { isSearching: isLoading || isFetching, searchResults, handleSearch: GetRiskRecord };
  }, [isLoading, isFetching, searchResults]);
};

import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@abyss/web/ui/Box';
import { IconSymbol } from '@abyss/web/ui/IconSymbol';
import { Layout } from '@abyss/web/ui/Layout';
import { Link } from '@abyss/web/ui/Link';

/**
 * WarningMessage
 *
 * Displays a warning message to the user when common criteria has changed.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const WarningMessage = (props) => {
  const { actionPath, criteria, context } = props;

  return (
    <Box
      color="transparent"
      css={{
        border: '1px solid var(--abyss-colors-warning1)',
        marginBottom: 'var(--abyss-space-lg)',
      }}
    >
      <Layout.Stack alignItems="left">
        <div>
          <Layout.Group>
            <div>
              <IconSymbol icon="warning" color="$warning1" size="24px" variant="outlined" />
            </div>
            <div>
              <strong>Common Criteria: {criteria?.name} has changed</strong>
            </div>
          </Layout.Group>
        </div>
        <div>Click the Review Criteria link to review the changes.</div>
        <div>
          {context === 'entrance' && criteria?.context?.includes('entrance') && (
            <Link href={`/action-paths/${actionPath?.id}/edit/draft/step/1`}>Review Entrance Criteria</Link>
          )}

          {context === 'exit' && criteria?.context?.includes('exit') && (
            <Link href={`/action-paths/${actionPath?.id}/edit/draft/step/4`}>Review Exit Criteria</Link>
          )}
        </div>
      </Layout.Stack>
    </Box>
  );
};

WarningMessage.propTypes = {
  actionPath: PropTypes.shape({
    id: PropTypes.string,
  }),
  criteria: PropTypes.shape({
    name: PropTypes.string,
    context: PropTypes.arrayOf(PropTypes.string),
  }),
  context: PropTypes.string,
};

WarningMessage.defaultProps = {
  actionPath: {},
  criteria: {},
  context: '',
};

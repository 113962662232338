import { ActionPaths } from './ActionPaths';
import { Attribute } from './Attribute';
import { Dates } from './Dates';
import { Eimp } from './Eimp';
import { EventTimeline } from './EventTimeline';
import { RemediationEvents } from './RemediationEvents';
import { Tags } from './Tags';
import { TrustedValueHistory } from './TrustedValueHistory';

/**
 * widgets
 *
 * @type {{"attribute-type-ssn": ((function(*): Element)|*), "remediation-events": ((function(*): Element)|*),
 *   "trusted-value-history": ((function(*): Element)|*), eimp: ((function(*): Element)|*), dates: ((function(*):
 *   Element)|*), "attribute-type-dob": ((function(*): Element)|*), "action-paths": ((function(*): Element)|*),
 *   "event-timeline": ((function(*): JSX.Element)|*), tags: ((function(*): Element)|*)}}
 */
export const widgets = {
  'action-paths': ActionPaths,
  'attribute-type-dob': Attribute,
  'attribute-type-ssn': Attribute,
  dates: Dates,
  eimp: Eimp,
  'event-timeline': EventTimeline,
  'remediation-events': RemediationEvents,
  tags: Tags,
  'trusted-value-history': TrustedValueHistory,
};

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Button } from '@src/components/Button';
import { ErrorHandler } from '@src/components/ErrorHandler';
import { isEqual } from 'lodash';
import { ActivationModal } from '../../modals/Activation';

/**
 * Button: Activate
 *
 * This button is used to activate the selected version of the entity.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
export const Activate = (props) => {
  const { activatedVersion, currentEntity, form, refetch, setFocusedEntity, commonCriteriaVersions } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { isDirty, isValid } = form?.formState;

  const selectedVersionValue = form?.watch('version');
  const currentFilters = form?.watch('filters');

  return (
    <ErrorHandler location="src/routes/private/Admin/screens/CommonCriteria/components/buttons/Activate/Activate.jsx">
      <Button
        variant="solid"
        type="submit"
        onClick={async () => {
          setIsOpen(true);
        }}
        isDisabled={
          !isDirty ||
          !isValid ||
          (activatedVersion?.version === selectedVersionValue && !isDirty) ||
          (activatedVersion?.version === selectedVersionValue && !isValid) ||
          isEqual(activatedVersion?.criteria, currentFilters)
        }
      >
        Activate
      </Button>
      {isOpen && (
        <ActivationModal
          currentEntity={currentEntity}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          refetch={refetch}
          formValues={form.getValues()}
          selectedVersionValue={selectedVersionValue}
          activatedVersion={activatedVersion}
          setFocusedEntity={setFocusedEntity}
          commonCriteriaVersions={commonCriteriaVersions}
        />
      )}
    </ErrorHandler>
  );
};

Activate.propTypes = {
  activatedVersion: PropTypes.shape({
    criteria: PropTypes.arrayOf(PropTypes.string),
    version: PropTypes.string,
  }),
  currentEntity: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  form: PropTypes.shape({
    formState: PropTypes.shape({
      isDirty: PropTypes.bool,
      isValid: PropTypes.bool,
    }),
    getValues: PropTypes.func,
    watch: PropTypes.func,
  }),
  refetch: PropTypes.func,
  setFocusedEntity: PropTypes.func,
  commonCriteriaVersions: PropTypes.number,
};

Activate.defaultProps = {
  activatedVersion: {},
  currentEntity: {},
  form: {},
  refetch: () => {},
  setFocusedEntity: () => {},
  commonCriteriaVersions: 0,
};
